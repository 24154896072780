import { useEffect, useRef, useState } from "react";

const useAutoFetch = (fetchData, intervalTime = 30000) => {
  const [timer, setTimer] = useState(intervalTime / 1000);
  const intervalRef = useRef(null);
  const [loading, setLoading] = useState(true); 
  const [data, setData] = useState(null); 

  // useEffect(() => {
  //   fetchData(); 
  //   intervalRef.current = setInterval(() => {
  //     setTimer((prev) => {
  //       if (prev === 1) {
  //         fetchData(); 
  //         return intervalTime / 1000;
  //       }
  //       return prev - 1;
  //     });
  //   }, 1000);

  //   return () => clearInterval(intervalRef.current); 
  // }, [fetchData, intervalTime]); 

  // useEffect(() => {
  //   let isMounted = true; 

  //   const startTimer = () => {
  //     intervalRef.current = setInterval(() => {
  //       setTimer((prev) => {
  //         if (prev === 1) {
  //           fetchData().then(() => {
  //             setTimer(intervalTime / 1000);
  //           });
  //           return intervalTime / 1000;
  //         }
  //         return prev - 1;
  //       });
  //     }, 1000);
  //   };


  //   fetchData().then(() => {
  //     if (isMounted) {
  //       setTimer(intervalTime / 1000);
  //       startTimer();
  //     }
  //   });

  //   return () => {
  //     isMounted = false;
  //     if (intervalRef.current) clearInterval(intervalRef.current);
  //   };
  // }, [fetchData, intervalTime]);

useEffect(() => {
  let isMounted = true;

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          fetchData().then((newData) => {
            if (isMounted) {
              setData(newData);
              setTimer(intervalTime / 1000);
            }
          });
          return intervalTime / 1000;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Fetch data first, then start timer
  fetchData()
    .then((newData) => {
      if (isMounted) {
        setData(newData); // Store fetched data
        setTimer(intervalTime / 1000); // Set timer after fetching
        startTimer();
      }
    })
    .finally(() => {
      if (isMounted) setLoading(false);
    });

  return () => {
    isMounted = false;
    if (intervalRef.current) clearInterval(intervalRef.current);
  };
}, [fetchData, intervalTime]);
  

   return { timer, loading, setTimer };
};

export default useAutoFetch;

import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { errorToast, successToast } from "../../../react-toastfiy/toast";
import auctionService from "../../../services/auction/auction.service";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const CreateBrandStatesModalPopup = (props) => {
  const {
    modal,
    data,
    setdata,
    setModal,
    initialValue,
    state,
    brand,
    setLoading,
    itemsPerPage,
    setBrandStateData,
    setFilteredData,
  } = props;
  const [selectState, setSelectState] = useState([]);
  const [errors, setErrors] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const source = axios.CancelToken.source();
  console.log({ state, data });

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      console.log("on Submit", { data });
      const errors = {};
      if (!data?.state_id) errors.state_id = "State Name is required.";
      if (!data?.brand_id) errors.brand_id = "Brand Name is required.";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      setButtonLoading(true);

      const form_data = new FormData();
      form_data.append("state_id", data?.state_id);
      form_data.append("brand_id", data?.brand_id);
      form_data.append("account_holder_name", data?.account_holder_name);
      form_data.append("bank_name", data?.bank_name);
      form_data.append("account_number", data?.account_number);
      form_data.append("is_acc_no_dynamic", data?.is_acc_no_dynamic);
      form_data.append("branch_name", data?.branch_name);
      form_data.append("ifsc", data?.ifsc);
      form_data.append("suffix_account_number", data?.suffix_account_number);
      form_data.append("prefix_account_number", data?.prefix_account_number);
      auctionService.CreateBrandState(form_data, source).then((res) => {
        setButtonLoading(false);
        if (res.status === 200) {
          console.log(res.msg, { res });
          successToast(res?.msg);
          setLoading(true);
          setModal(false);
          auctionService.GetBrandState(source).then((res) => {
            setLoading(false);

            console.log({ res });
            if (res?.status === 200) {
              setdata(initialValue);
              const brand_list = res?.data?.map((val, index) => {
                return {
                  ...val,
                  no: (1 - 1) * itemsPerPage + (index + 1),
                };
              });
              setBrandStateData(brand_list);
              setFilteredData(brand_list);
            } else {
              setBrandStateData([]);
            }
          });
        } else {
          errorToast(res?.msg);
          console.log({ res });
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    try {
      const errors = {};
      if (!data?.state_id) errors.state_id = "State Name is required.";
      if (!data?.brand_id) errors.brand_id = "Brand Name is required.";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      setButtonLoading(true);
      const form_data = new FormData();
      form_data.append("state_id", data?.state_id);
      form_data.append("brand_id", data?.brand_id);
      form_data.append("account_holder_name", data?.account_holder_name);
      form_data.append("bank_name", data?.bank_name);
      form_data.append("account_number", data?.account_number);
      form_data.append("is_acc_no_dynamic", data?.is_acc_no_dynamic);
      form_data.append("branch_name", data?.branch_name);
      form_data.append("ifsc", data?.ifsc);
      form_data.append("suffix_account_number", data?.suffix_account_number);
      form_data.append("prefix_account_number", data?.prefix_account_number);
      auctionService.UpdateBrandState(form_data, source).then((res) => {
        setButtonLoading(false);
        if (res.status === 200) {
          successToast(res?.msg);
          setLoading(true);
          setModal(false);
          auctionService.GetBrandState(source).then((res) => {
            setLoading(false);
            console.log({ res });
            if (res?.status === 200) {
              setdata(initialValue);
              const brand_list = res?.data?.map((val, index) => {
                return {
                  ...val,
                  no: (1 - 1) * itemsPerPage + (index + 1),
                };
              });
              setBrandStateData(brand_list);
              setFilteredData(brand_list);
            } else {
              setBrandStateData([]);
            }
          });
        } else {
          errorToast(res?.msg);
          console.log({ res });
        }
      });
    } catch (error) {
      errorToast(error.msg);
    }
  };
  const handleSelect = (selectedOptions) => {
    setdata((prev) => ({
      ...prev,
      state_id: selectedOptions?.value?.id,
      ["state"]: selectedOptions?.label,
    }));
  };
  const handleSelectBrand = (selectedBrand) => {
    console.log({ selectedBrand });
    setdata((prev) => ({
      ...prev,
      brand_id: selectedBrand?.value?.id,
      ["brand"]: selectedBrand?.label,
    }));
  };

  return (
    <Modal
      {...props}
      size="md"
      backdrop="static"
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        {data?.no ? <h5>Update Brand States</h5> : <h5>Create Brand States</h5>}
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-wrap justify-content-between mt-2 gap-2">
          <div className="col-12">
            <label>Select Brand Name</label>
            <Select
              options={brand}
              value={brand.filter(
                (option) => option?.value?.id === data?.brand_id
              )}
              onChange={handleSelectBrand}
              required
            />
          </div>
          <div className="col-12">
            <label>Select State</label>
            <Select
              options={state}
              value={state.filter(
                (option) => option.value?.id === data?.state_id
              )}
              onChange={handleSelect}
              // closeMenuOnSelect={false}
              required
            />
          </div>
          <div className="col-12">
            <label>A/c Holder Name</label>
            <input
              className="form-control"
              type="text"
              name="account_holder_name"
              placeholder="Account holder name..."
              value={data?.account_holder_name}
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["account_holder_name"]: e.target.value,
                }));
              }}
            />
          </div>
          <div className="col-12">
            <label>Bank Name</label>
            <input
              className="form-control"
              type="text"
              name="bank_name"
              placeholder="Bank name..."
              value={data?.bank_name}
              onChange={(e) => {
                setdata((prev) => ({ ...prev, ["bank_name"]: e.target.value }));
              }}
            />
          </div>
          <div className="col-12">
            <label>Branch Name</label>
            <input
              className="form-control"
              type="text"
              name="branch_name"
              placeholder="Branch name..."
              value={data?.branch_name}
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["branch_name"]: e.target.value,
                }));
              }}
            />
          </div>
          <div className="col-12">
            <label>Prefix Account No.</label>
            <input
              className="form-control"
              type="text"
              name="prefix_account_number"
              placeholder="Prefix account number..."
              value={data?.prefix_account_number}
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["prefix_account_number"]: e.target.value,
                }));
              }}
            />
          </div>
          <div className="col-12">
            <label>Account No.</label>

            <input
              className="form-control"
              type="text"
              name="account_number"
              placeholder="Account number..."
              value={data?.account_number}
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["account_number"]: e.target.value,
                }));
              }}
              disabled={data?.is_acc_no_dynamic === true}
            />
            <input
              className="form-check-input me-1"
              type="checkbox"
              style={{ marginLeft: "0px" }}
              name="is_acc_no_dynamic"
              placeholder="Account number..."
              checked={data?.is_acc_no_dynamic}
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["is_acc_no_dynamic"]: e.target.checked,
                }));
              }}
            />
            <label>Is Dynamic ?</label>
          </div>
          <div className="col-12">
            <label>Suffix Account No.</label>
            <input
              className="form-control"
              type="text"
              name="suffix_account_number"
              placeholder="Suffix account number..."
              value={data?.suffix_account_number}
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["suffix_account_number"]: e.target.value,
                }));
              }}
            />
          </div>
          <div className="col-12">
            <label>IFSC</label>
            <input
              className="form-control"
              type="text"
              name="ifsc"
              placeholder="Ifsc..."
              value={data?.ifsc}
              onChange={(e) => {
                setdata((prev) => ({
                  ...prev,
                  ["ifsc"]: e.target.value,
                }));
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>
          Close
        </Button>

        {buttonLoading ? (
          <button
            className="btn btn-primary"
            type="button"
            disabled
            id="loadingBtnTp"
          >
            <span
              className="spinner-border spinner-border-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </button>
        ) : data?.no !== "" ? (
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        ) : (
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateBrandStatesModalPopup;

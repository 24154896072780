import jwt from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ErrorHandler from "../../common/ErrorHandler";
import "../../css/dashboard.css";
import usePaymentPendingCount from "../../hooks/usePaymentPendingCount";

import AuctionOverview from "../dashboard/AuctionOverview";

function LiveAuctionDashboard() {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwt(currentUser);

  let history = useHistory();

  const [active, setactive] = useState("auction_dashboard");

  const PaymentPendingCount = usePaymentPendingCount(active);

  const handlechangetab = (e) => {
    const id = e.target.getAttribute("id");
    setactive(id);
    console.log({id})
    localStorage.setItem("active_tab", id);
  };

  useEffect(() => {
    const lastActiveTab = localStorage.getItem("active_tab");
    console.log({ lastActiveTab });
    if (lastActiveTab) {
      setactive(lastActiveTab);
    }else{
          localStorage.setItem("active_tab", "auction_dashboard");
       setactive("auction_dashboard");
    }
  }, []);

  try {
    return (
      <>
        <div className="container-fluid shadow-sm">
          <div className="container">
            <div className="row">
              <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                  </div>
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12 col-md-6 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-6 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-none d-lg-block d-xl-block">
                <div className="row">
                  <div className="col-12 col-md-8 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-4 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        
        <div className="container mt-3">
          <div className="d-flex justify-content-between">
            <i
              className="bi bi-arrow-left-circle"
              onClick={() =>
                history.push(
                  `/auction_representative:${user?.phone}&state=${user?.state}`
                )
              }
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </div>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={
                  active === "auction_dashboard"
                    ? `nav-link active btn btn-link`
                    : `nav-link btn btn-link`
                }
                aria-current="page"
                id="auction_dashboard"
                onClick={handlechangetab}
              >
                Live
              </button>
            </li>
          </ul>
        </div>
        {active === "auction_dashboard" && (
          <>
            <AuctionOverview />
          </>
        )}
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
  // }
}

export default LiveAuctionDashboard;

import jwt from "jwt-decode";
import React, { lazy, Suspense, useEffect, useRef } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import "./App.css";

import "react-toastify/dist/ReactToastify.css";
import ChangeRoute from "./common/ChangeRoute.jsx";
import ComingSoonPage from "./common/ComingSoonPage";
import FailurePage from "./common/FailurePage.jsx";
import Navbar from "./common/navbar.jsx";
import NotFound from "./common/notFound.jsx";
import SuperAdminNavbar from "./common/SuperAdminNavbar.jsx";
import ThankYouPage from "./common/ThankYouPage.jsx";
import ViewPDFPage from "./components/bharatLoans/pdf/ViewPDFPage.jsx";
import Login from "./components/login.jsx";
import Logout from "./components/logout.jsx";

// import AddAsset from "./components/meratractor/AddAsset.jsx";
// import AddUserWallet from "./components/meratractor/AddUserWallet.jsx";
// import AuctionACR from "./components/meratractor/AuctionACR.jsx";
// import AuctionAssetStatus from "./components/meratractor/AuctionAssetStatus.jsx";
// import AuctionHome from "./components/meratractor/AuctionHome.jsx";
// import ARViewAuction from "./components/meratractor/auctionrepresentative/ARViewAuction.jsx";
// import AuctionSummary from "./components/meratractor/AuctionSummary.jsx";
// import BrandHome from "./components/meratractor/brand/BrandHome.jsx";
// import ManageBrand from "./components/meratractor/brand/ManageBrand.jsx";
// import EditAuctionHome from "./components/meratractor/EditAuctionHome.jsx";
// import FinancierHome from "./components/meratractor/financier/FinancierHome.jsx";
// import ManageFinancier from "./components/meratractor/financier/ManageFinancier.jsx";
// import LogoutUser from "./components/meratractor/LogoutUser.jsx";
// import MeratractorHome from "./components/meratractor/MeratractorHome.jsx";
// import ModifyAssets from "./components/meratractor/ModifyAssets.jsx";
// import SearchAssetImage from "./components/meratractor/SearchAssetImage.jsx";
// import ManageSegment from "./components/meratractor/segment/ManageSegment.jsx";
// import SegmentHome from "./components/meratractor/segment/SegmentHome.jsx";
// import SingleUpload from "./components/meratractor/SingleUpload.jsx";
// import ManageState from "./components/meratractor/state/ManageState.jsx";
// import StateHome from "./components/meratractor/state/StateHome.jsx";
// import UploadImageExcel from "./components/meratractor/UploadImageExcel.jsx";
// import UploadMultipleFile from "./components/meratractor/UploadMultipleFile.jsx";
// import ViewAuction from "./components/meratractor/ViewAuction.jsx";
// import ViewAuctionAsset from "./components/meratractor/ViewAuctionAsset.jsx";
// import MultiUserTransactionHome from "./components/meratractor/wallet/multiuser/MultiUserTransactionHome.jsx";
// import UploadMultipleTransaction from "./components/meratractor/wallet/multiuser/UploadMultipleTransaction.jsx";
// import ViewAllTransactions from "./components/meratractor/wallet/multiuser/ViewAllTransactions.jsx";
// import SingleUserTransaction from "./components/meratractor/wallet/singleuser/SingleUserTransaction.jsx";
// import SingleUserTransectionHome from "./components/meratractor/wallet/singleuser/SingleUserTransectionHome.jsx";
// import UploadMultipleTransactionForSingleUser from "./components/meratractor/wallet/singleuser/UploadMultipleTransactionForSingleUser.jsx";
// import ViewTransactionOfUsers from "./components/meratractor/wallet/singleuser/ViewTransationOfUsers.jsx";
// import UserBalanceList from "./components/meratractor/wallet/UserBalanceList.jsx";
// import UserNotLinkedWithWallet from "./components/meratractor/wallet/UserNotLinkedWithWallet.jsx";
// import WalletHome from "./components/meratractor/wallet/WalletHome.jsx";
// import RapibidEditAuctionHome from "./components/rapidbid/RapibidEditAuctionHome.jsx";
// import RapidbidHomePage from "./components/rapidbid/RapidbidHomePage.jsx";
// import ActiveSubscriptionList from "./components/rapidbid/subscription/ActiveSubscriptionList.jsx";
// import AddAuction from "./components/rapidbid/subscription/AddAuction.jsx";
// import AuctionAddDocuments from "./components/rapidbid/subscription/AuctionAddDocuments.jsx";
// import AuctionAddImage from "./components/rapidbid/subscription/AuctionAddImage.jsx";
// import AuctionDownloadDocuments from "./components/rapidbid/subscription/AuctionDownloadDocuments.jsx";
// import AuctionEditImage from "./components/rapidbid/subscription/AuctionEditImage.jsx";
// import CreatePlans from "./components/rapidbid/subscription/CreatePlans.jsx";
// import DraftPlan from "./components/rapidbid/subscription/DraftPlan.jsx";
// import EditAuctionForm from "./components/rapidbid/subscription/EditAuctionForm.jsx";
// import ExpiredUsersSubscriptionlist from "./components/rapidbid/subscription/ExpiredUsersSubscriptionlist.jsx";
// import Make from "./components/rapidbid/subscription/Make.jsx";
// import Model from "./components/rapidbid/subscription/Model.jsx";
// import Permissions from "./components/rapidbid/subscription/Permission.jsx";
// import PlanHomePage from "./components/rapidbid/subscription/PlanHomePage.jsx";
// import PublishedPlan from "./components/rapidbid/subscription/PublishedPlan.jsx";
// import RapibidAddUser from "./components/rapidbid/subscription/RapibidAddUser.jsx";
// import RapibidAuctionHome from "./components/rapidbid/subscription/RapibidAuctionHome.jsx";
// import RapibidAuctionSummary from "./components/rapidbid/subscription/RapibidAuctionSummary.jsx";
// import RapibidAuctionTabbar from "./components/rapidbid/subscription/RapibidAuctionTabbar.jsx";
// import RapibidStateList from "./components/rapidbid/subscription/RapibidStateList.jsx";
// import RapibidUserList from "./components/rapidbid/subscription/RapibidUserList.jsx";
// import RefundApprovalPendingList from "./components/rapidbid/subscription/RefundApprovalPendingList.jsx";
// import RefundTransectionHistory from "./components/rapidbid/subscription/RefundTransectionHistory.jsx";
// import SubscriptionHomePage from "./components/rapidbid/subscription/SubscriptionHomePage.jsx";
// import SubscriptionUserListHomePage from "./components/rapidbid/subscription/SubscriptionUserListHomePage.jsx";
// import TransactionHistoryForAllUsers from "./components/rapidbid/subscription/TransactionHistoryForAllUsers.jsx";
// import UserLoginDetails from "./components/rapidbid/subscription/UserLoginDetails.jsx";
// import PlatformTenureList from "./components/subscription/PlatformTenureList.jsx";
// import SubscriptionHome from "./components/subscription/SubscriptionHome.jsx";
// import SubscriptionCreatePlans from "./components/subscription/CreatePlan.jsx";
// import SubscriptionDraftPlan from "./components/subscription/DraftPlan.jsx";
// import SubscriptionPlanHomePage from "./components/subscription/PlanHomePage.jsx";
// import SubscriptionPublishedPlan from "./components/subscription/PublishedPlan.jsx";
// import SubscriptionRefundApprovalPendingList from "./components/subscription/RefundApprovalPendingList.jsx";
// import SubscriptionRefundTransectionHistory from "./components/subscription/SubscriptionRefundTransectionHistory.jsx";
// import SubscriptionTransactionHistoryForAllUsers from "./components/subscription/TransactionHistoryForAllUsers.jsx";

import { useHistory } from "react-router-dom";
import YardOwnerNavbar from "./common/YardOwnerNavbar.jsx";
import ManageBrandStates from "./components/meratractor/brandstates/ManageBrandStates.jsx";
import LiveAuctionDashboard from "./components/meratractor/LiveAuctionDashboard.jsx";
import AuctionAssetsData from "./components/dashboard/AuctionAssetsData.jsx";
import LiveAuctionDashboardBackOffice from "./components/meratractor/LiveAuctionDashboardBackOffice.jsx";
import LiveAuctionDashboardASA from "./components/meratractor/LiveAuctionDashboardASA.jsx";

const ActionBackOfficeHome = lazy(() =>
  import("./components/auction/AuctionBackOffice/ActionBackOfficeHome.jsx")
);
const ASAHome = lazy(() => import("./components/dashboard/ASAHome.jsx"));
const BusinessAnalystDashboard = lazy(() =>
  import("./components/dashboard/BusinessAnalystDashboard.jsx")
);

const AddEditOrViewUserByAuctionRepresentative = lazy(() =>
  import("./components/user/AddEditOrViewUserByAuctionRepresentative.jsx")
);
const UnifiedUsersList = lazy(() =>
  import("./components/user/UnifiedUsersList.jsx")
);

const AddAsset = lazy(() => import("./components/meratractor/AddAsset.jsx"));
const UpdateMultipleAssetsStartPrice = lazy(() =>
  import(
    "./components/meratractor/auctionrepresentative/UpdateMultipleAssetsStartPrice.jsx"
  )
);

const AuctionACR = lazy(() => import("./components/meratractor/AuctionACR"));
const AddUserWallet = lazy(() =>
  import("./components/meratractor/AddUserWallet.jsx")
);
const AddAssetImage = lazy(() =>
  import("./components/meratractor/AddAssetImage.jsx")
);
const AddAuctionMeratractor = lazy(() =>
  import("./components/meratractor/AddAuction.jsx")
);
const ARDashboard = lazy(() =>
  import("./components/meratractor/ARDashboard.jsx")
);
const AssetSummary = lazy(() =>
  import("./components/meratractor/AssetSummary.jsx")
);
const ARAuctionHome = lazy(() =>
  import("./components/meratractor/auctionrepresentative/ARAuctionHome.jsx")
);
const ARUserHome = lazy(() =>
  import("./components/meratractor/auctionrepresentative/ARUserHome.jsx")
);
const AutobidData = lazy(() =>
  import("./components/meratractor/AutobidData.jsx")
);
const EditAssetDetails = lazy(() =>
  import("./components/meratractor/EditAssetDetails.jsx")
);
const EditAssetImage = lazy(() =>
  import("./components/meratractor/EditAssetImage.jsx")
);
const MaratractorPermissions = lazy(() =>
  import("./components/meratractor/Permissions.jsx")
);

const AuctionAssetStatus = lazy(() =>
  import("./components/meratractor/AuctionAssetStatus.jsx")
);
const AuctionHome = lazy(() =>
  import("./components/meratractor/AuctionHome.jsx")
);
const ARViewAuction = lazy(() =>
  import("./components/meratractor/auctionrepresentative/ARViewAuction.jsx")
);
const AuctionSummary = lazy(() =>
  import("./components/meratractor/AuctionSummary.jsx")
);
const BrandHome = lazy(() =>
  import("./components/meratractor/brand/BrandHome.jsx")
);
const ManageBrand = lazy(() =>
  import("./components/meratractor/brand/ManageBrand.jsx")
);
const EditAuctionHome = lazy(() =>
  import("./components/meratractor/EditAuctionHome.jsx")
);
const FinancierHome = lazy(() =>
  import("./components/meratractor/financier/FinancierHome.jsx")
);
const ManageFinancier = lazy(() =>
  import("./components/meratractor/financier/ManageFinancier.jsx")
);
const LogoutUser = lazy(() =>
  import("./components/meratractor/LogoutUser.jsx")
);
const MeratractorHome = lazy(() =>
  import("./components/meratractor/MeratractorHome.jsx")
);
const ModifyAssets = lazy(() =>
  import("./components/meratractor/ModifyAssets.jsx")
);
const SearchAssetImage = lazy(() =>
  import("./components/meratractor/SearchAssetImage.jsx")
);
const ManageSegment = lazy(() =>
  import("./components/meratractor/segment/ManageSegment.jsx")
);
const SegmentHome = lazy(() =>
  import("./components/meratractor/segment/SegmentHome.jsx")
);
const SingleUpload = lazy(() =>
  import("./components/meratractor/SingleUpload.jsx")
);
const ManageState = lazy(() =>
  import("./components/meratractor/state/ManageState.jsx")
);
const StateHome = lazy(() =>
  import("./components/meratractor/state/StateHome.jsx")
);
const UploadImageExcel = lazy(() =>
  import("./components/meratractor/UploadImageExcel.jsx")
);
const UploadMultipleFile = lazy(() =>
  import("./components/meratractor/UploadMultipleFile.jsx")
);
const ViewAuction = lazy(() =>
  import("./components/meratractor/ViewAuction.jsx")
);
const ViewAuctionAsset = lazy(() =>
  import("./components/meratractor/ViewAuctionAsset.jsx")
);
const MultiUserTransactionHome = lazy(() =>
  import(
    "./components/meratractor/wallet/multiuser/MultiUserTransactionHome.jsx"
  )
);
const UploadMultipleTransaction = lazy(() =>
  import(
    "./components/meratractor/wallet/multiuser/UploadMultipleTransaction.jsx"
  )
);
const ViewAllTransactions = lazy(() =>
  import("./components/meratractor/wallet/multiuser/ViewAllTransactions.jsx")
);
const SingleUserTransaction = lazy(() =>
  import("./components/meratractor/wallet/singleuser/SingleUserTransaction.jsx")
);
const SingleUserTransectionHome = lazy(() =>
  import(
    "./components/meratractor/wallet/singleuser/SingleUserTransectionHome.jsx"
  )
);
const UploadMultipleTransactionForSingleUser = lazy(() =>
  import(
    "./components/meratractor/wallet/singleuser/UploadMultipleTransactionForSingleUser.jsx"
  )
);

const ViewTransactionOfUsers = lazy(() =>
  import("./components/meratractor/wallet/singleuser/ViewTransationOfUsers.jsx")
);
const UserBalanceList = lazy(() =>
  import("./components/meratractor/wallet/UserBalanceList.jsx")
);
const UserNotLinkedWithWallet = lazy(() =>
  import("./components/meratractor/wallet/UserNotLinkedWithWallet.jsx")
);
const WalletHome = lazy(() =>
  import("./components/meratractor/wallet/WalletHome.jsx")
);

const RapibidEditAuctionHome = lazy(() =>
  import("./components/rapidbid/RapibidEditAuctionHome.jsx")
);
const RapidbidHomePage = lazy(() =>
  import("./components/rapidbid/RapidbidHomePage.jsx")
);
const ActiveSubscriptionList = lazy(() =>
  import("./components/rapidbid/subscription/ActiveSubscriptionList.jsx")
);
const AddAuction = lazy(() =>
  import("./components/rapidbid/subscription/AddAuction.jsx")
);
const AuctionAddDocuments = lazy(() =>
  import("./components/rapidbid/subscription/AuctionAddDocuments.jsx")
);
const AuctionAddImage = lazy(() =>
  import("./components/rapidbid/subscription/AuctionAddImage.jsx")
);
const AuctionDownloadDocuments = lazy(() =>
  import("./components/rapidbid/subscription/AuctionDownloadDocuments.jsx")
);
const AuctionEditImage = lazy(() =>
  import("./components/rapidbid/subscription/AuctionEditImage.jsx")
);
const CreatePlans = lazy(() =>
  import("./components/rapidbid/subscription/CreatePlans.jsx")
);
const DraftPlan = lazy(() =>
  import("./components/rapidbid/subscription/DraftPlan.jsx")
);
const EditAuctionForm = lazy(() =>
  import("./components/rapidbid/subscription/EditAuctionForm.jsx")
);
const ExpiredUsersSubscriptionlist = lazy(() =>
  import("./components/rapidbid/subscription/ExpiredUsersSubscriptionlist.jsx")
);
const Make = lazy(() => import("./components/rapidbid/subscription/Make.jsx"));
const Model = lazy(() =>
  import("./components/rapidbid/subscription/Model.jsx")
);
const Permissions = lazy(() =>
  import("./components/rapidbid/subscription/Permission.jsx")
);
const PlanHomePage = lazy(() =>
  import("./components/rapidbid/subscription/PlanHomePage.jsx")
);
const PublishedPlan = lazy(() =>
  import("./components/rapidbid/subscription/PublishedPlan.jsx")
);
const RapibidAddUser = lazy(() =>
  import("./components/rapidbid/subscription/RapibidAddUser.jsx")
);
const RapibidAuctionHome = lazy(() =>
  import("./components/rapidbid/subscription/RapibidAuctionHome.jsx")
);
const RapibidAuctionSummary = lazy(() =>
  import("./components/rapidbid/subscription/RapibidAuctionSummary.jsx")
);
const RapibidAuctionTabbar = lazy(() =>
  import("./components/rapidbid/subscription/RapibidAuctionTabbar.jsx")
);
const RapibidStateList = lazy(() =>
  import("./components/rapidbid/subscription/RapibidStateList.jsx")
);
const RapibidUserList = lazy(() =>
  import("./components/rapidbid/subscription/RapibidUserList.jsx")
);
const RefundApprovalPendingList = lazy(() =>
  import("./components/rapidbid/subscription/RefundApprovalPendingList.jsx")
);
const RefundTransectionHistory = lazy(() =>
  import("./components/rapidbid/subscription/RefundTransectionHistory.jsx")
);
const SubscriptionHomePage = lazy(() =>
  import("./components/rapidbid/subscription/SubscriptionHomePage.jsx")
);
const SubscriptionUserListHomePage = lazy(() =>
  import("./components/rapidbid/subscription/SubscriptionUserListHomePage.jsx")
);
const TransactionHistoryForAllUsers = lazy(() =>
  import("./components/rapidbid/subscription/TransactionHistoryForAllUsers.jsx")
);
const UserLoginDetails = lazy(() =>
  import("./components/rapidbid/subscription/UserLoginDetails.jsx")
);
const PlatformTenureList = lazy(() =>
  import("./components/subscription/PlatformTenureList.jsx")
);
const SubscriptionHome = lazy(() =>
  import("./components/subscription/SubscriptionHome.jsx")
);
const SubscriptionCreatePlans = lazy(() =>
  import("./components/subscription/CreatePlan.jsx")
);
const SubscriptionDraftPlan = lazy(() =>
  import("./components/subscription/DraftPlan.jsx")
);
const SubscriptionPlanHomePage = lazy(() =>
  import("./components/subscription/PlanHomePage.jsx")
);
const SubscriptionPublishedPlan = lazy(() =>
  import("./components/subscription/PublishedPlan.jsx")
);
const SubscriptionRefundApprovalPendingList = lazy(() =>
  import("./components/subscription/RefundApprovalPendingList.jsx")
);
const SubscriptionRefundTransectionHistory = lazy(() =>
  import("./components/subscription/SubscriptionRefundTransectionHistory.jsx")
);
const SubscriptionTransactionHistoryForAllUsers = lazy(() =>
  import("./components/subscription/TransactionHistoryForAllUsers.jsx")
);

const ASAUserHome = lazy(() => import("./components/auction/ASAUserHome.jsx"));
const ASAViewEditUserList = lazy(() =>
  import("./components/auction/ASAViewEditUserList.jsx")
);
const ASAEditUser = lazy(() => import("./components/auction/ASAEditUser.jsx"));

const SuperAdminHome = lazy(() => import("./components/SuperAdminHome.jsx"));
const FinancierWiseAM = lazy(() =>
  import("./components/Dealerships/RCStatus/FinancierWiseAM.jsx")
);
const SuperAdminRTOReportTab = lazy(() =>
  import("./common/reports/SuperAdminRTOReport.jsx")
);
const AssetListForApprovalPending = lazy(() =>
  import("./components/auction/AssetListForApprovalPending.jsx")
);
const AssetListForAuctionReport = lazy(() =>
  import("./components/auction/AssetListForAuctionReport.jsx")
);
const AssetListForPaymentPending = lazy(() =>
  import("./components/auction/AssetListForPaymentPending.jsx")
);
const NameTransferCaseAMWiseTMTable = lazy(() =>
  import("./components/Dealerships/RCStatus/NameTransferCaseAMWiseTMTable.jsx")
);
const NameTransferTMWiseDealership = lazy(() =>
  import("./components/Dealerships/RCStatus/NameTransferTMWiseDealership.jsx")
);
const RCLimitCaseAMWiseTMTable = lazy(() =>
  import("./components/Dealerships/RCStatus/RCLimitCaseAMWiseTMTable.jsx")
);
const RCLimitTMWiseDealershipTable = lazy(() =>
  import("./components/Dealerships/RCStatus/RCLimitTMWiseDealershipTable.jsx")
);
const ProductwiseInventorysummary = lazy(() =>
  import(
    "./components/implements/dashboard/salesdashbord/dealer/dealerinventory/ProductWiseInventorySummmary.jsx"
  )
);
const DealerWiseCategoryWiseSalesSummary = lazy(() =>
  import(
    "./components/implements/dashboard/salesdashbord/dealer/dealersales/DealerWiseCategoryWiseSalesSummary.jsx"
  )
);
const DealerCategoryWiseWarrantySummary = lazy(() =>
  import(
    "./components/implements/dashboard/warrantydashboad/DealerCategoryWiseWarrantySummary.jsx"
  )
);
const CategoryHome = lazy(() =>
  import("./components/implements/inventory/category/CategoryHome.jsx")
);
const DelearInventory = lazy(() =>
  import("./components/implements/inventory/DelearInventory.jsx")
);
const InventoryHomePage = lazy(() =>
  import("./components/implements/inventory/InventoryHomePage.jsx")
);
const MTInventroy = lazy(() =>
  import("./components/implements/inventory/MTInventroy.jsx")
);
const SefakiInventory = lazy(() =>
  import("./components/implements/inventory/SefakiInventory.jsx")
);
const ManufactureView = lazy(() =>
  import("./components/implements/manufacturer/ManufacturerView.jsx")
);
const RtoDashboardCode = lazy(() =>
  import("./components/dashboard/rtoDashboardCode.jsx")
);

const RCDataDetails = lazy(() =>
  import("./components/Dealerships/RCDataDetails.jsx")
);

const rtoFinancierWiseDashboard = lazy(() =>
  import("./components/dashboard/rtoFinancierWiseDashboard.jsx")
);

const Loans = lazy(() => import("./components/forms/loans.jsx"));

const AMDashboard = lazy(() =>
  import("./components/dashboard/AMDashboard.jsx")
);
const AuctionDashboard = lazy(() =>
  import("./components/dashboard/AuctionDashboard.jsx")
);
const ASADashboard = lazy(() =>
  import("./components/dashboard/ASADashboard.jsx")
);

const ADUDashboard = lazy(() =>
  import("./components/auction/ADUdashboard.jsx")
);
const AuctionAdminSHDashboard = lazy(() =>
  import("./components/dashboard/AuctionAdminSHDashboard.jsx")
);

const UserHome = lazy(() => import("./components/user/UserHome.jsx"));
const RapibidHome = lazy(() => import("./components/rapidbid/RapibidHome.jsx"));
const RapibidReports = lazy(() =>
  import("./components/rapidbid/RapibidReports.jsx")
);
const RapibidDashboard = lazy(() =>
  import("./components/rapidbid/RapibidDashboard.jsx")
);
const UserProfile = lazy(() => import("./components/user/UserProfile.jsx"));
const State = lazy(() => import("./components/user/State.jsx"));
const Tehsil = lazy(() => import("./components/user/Tehsil.jsx"));
const District = lazy(() => import("./components/user/District.jsx"));
const WishListCode = lazy(() =>
  import("./components/Dealerships/stockwishlist/WishListCode.jsx")
);

const ViewEditUsers = lazy(() => import("./components/user/ViewEditUsers.jsx"));
const AddEditUser = lazy(() => import("./components/user/AddEditUser.jsx"));
const AllowToMarkAttendanceTable = lazy(() =>
  import("./common/HR/AllowToMarkAttendanceTable.jsx")
);
const Cluster = lazy(() => import("./components/user/Cluster.jsx"));
const TractorpointHome = lazy(() =>
  import("./components/tractorpoint/tpHome.jsx")
);
const AddTractorpoint = lazy(() =>
  import("./components/tractorpoint/addTP.jsx")
);
const ListTractorpoint = lazy(() =>
  import("./components/tractorpoint/listTP.jsx")
);
const AddUser = lazy(() => import("./components/user/addUser.jsx"));
const EditUser = lazy(() => import("./components/user/editUser.jsx"));
const EditTP = lazy(() => import("./components/tractorpoint/editTP.jsx"));
const Designation = lazy(() => import("./components/user/Designation.jsx"));
const ManageBusiness = lazy(() =>
  import("./components/user/ManageBusiness.jsx")
);
const AddMake = lazy(() => import("./components/tractorpoint/addMake.jsx"));
const AddModel = lazy(() => import("./components/tractorpoint/addModel.jsx"));
const InventoryHome = lazy(() =>
  import("./components/tractorpoint/inventoryHome.jsx")
);
const AddTractor = lazy(() =>
  import("./components/tractorpoint/addTractor.jsx")
);
const ListTractors = lazy(() =>
  import("./components/tractorpoint/listTractors.jsx")
);
const OperationHome = lazy(() =>
  import("./components/operation/OperationHome.jsx")
);
const AddActivity = lazy(() =>
  import("./components/operation/AddActivity.jsx")
);
const ViewEditActivity = lazy(() =>
  import("./components/operation/ViewEditActivity.jsx")
);
const Dashboard = lazy(() => import("./components/dashboard/dashboard.jsx"));
const EditActivity = lazy(() =>
  import("./components/operation/EditActivity.jsx")
);
const MilestonesDash = lazy(() =>
  import("./components/dashboard/milestonesDash.jsx")
);
const EditDraftActivity = lazy(() =>
  import("./components/operation/EditDraftActivity.jsx")
);
const ListDashboardData = lazy(() =>
  import("./components/dashboard/listDashData.jsx")
);
const RapibidAuctionApproval = lazy(() =>
  import("./components/dashboard/rapibidAuctionApproval.jsx")
);
const AddTask = lazy(() => import("./components/dashboard/addTask.jsx"));
const AddOperation = lazy(() =>
  import("./components/operation/AddOperation.jsx")
);
const ViewEditOperation = lazy(() =>
  import("./components/operation/ViewEditOperation.jsx")
);
const EditOperation = lazy(() =>
  import("./components/operation/EditOperation.jsx")
);
const ViewEditDraftActivity = lazy(() =>
  import("./components/operation/ViewEditDraftActivity.jsx")
);
const EditOperationData = lazy(() =>
  import("./components/operation/EditOperationData.jsx")
);
const ExchangeDealerCode = lazy(() =>
  import("./components/user/ExchangeDealerCode.jsx")
);
const CommisionAgents = lazy(() =>
  import("./components/user/CommisionAgents.jsx")
);
const ChangePassword = lazy(() =>
  import("./components/user/ChangePassword.jsx")
);
const LoginNavbar = lazy(() => import("./common/LoginNavbar.jsx"));
const SuperAdminRoute = lazy(() => import("./common/SuperAdminRoute.jsx"));
const DeveloperRoute = lazy(() => import("./common/DeveloperRoute.jsx"));
const Financier = lazy(() => import("./components/tractorpoint/Financier.jsx"));
const UserHierarchy = lazy(() => import("./components/user/UserHierarchy.jsx"));
const CompletedTask = lazy(() =>
  import("./components/dashboard/completedTask.jsx")
);
const CompletedTaskDetailPage = lazy(() =>
  import("./components/dashboard/completedTaskDetailPage.jsx")
);
const SubOrdinateUserData = lazy(() =>
  import("./components/dashboard/subOrdinateUserData.jsx")
);
const DealershipReportsHome = lazy(() =>
  import("./components/reports/DealershipReportsHome.jsx")
);
const BharatLoanReport = lazy(() =>
  import("./components/bharatLoans/reports/BharatLoanReport.jsx")
);
const UserRoute = lazy(() => import("./common/UserRoute.jsx"));
const UserNavbar = lazy(() => import("./common/UserNavbar.jsx"));
const ADUNavbar = lazy(() => import("./common/ADUNavbar.jsx"));
const ProcurementViaAuction = lazy(() =>
  import("./components/forms/ProcurementViaAuction.jsx")
);
const ProcurementViaDealership = lazy(() =>
  import("./components/forms/ProcurementViaDealership.jsx")
);
const ProcurementViaCommision = lazy(() =>
  import("./components/forms/ProcurementViaCommision.jsx")
);
const ProcurementViaF2B = lazy(() =>
  import("./components/forms/ProcurementViaF2B.jsx")
);
const LoanSalePhase = lazy(() =>
  import("./components/forms/LoanSalePhase.jsx")
);
const CashSalePhase = lazy(() =>
  import("./components/forms/CashSalePhase.jsx")
);
const SaleStock = lazy(() => import("./components/forms/SaleStock.jsx"));

const level2Dashboard = lazy(() =>
  import("./components/dashboard/level2Dashboard.jsx")
);
const superAdminDashboard = lazy(() =>
  import("./components/dashboard/superAdminDashboard.jsx")
);
const tmDashboard = lazy(() =>
  import("./components/dashboard/tmDashboard.jsx")
);
const arDashboard = lazy(() =>
  import("./components/dashboard/arDashboard.jsx")
);
const rtoDashboard = lazy(() =>
  import("./components/dashboard/rtoDashboard.jsx")
);
const apDashboard = lazy(() =>
  import("./components/dashboard/apDashboard.jsx")
);
const StateHeadDashboard = lazy(() =>
  import("./components/dashboard/StateHeadDashboard.jsx")
);
const StateWiseDataDashboard = lazy(() =>
  import("./components/dashboard/StateWiseDataDashboard.jsx")
);
const BusinessDealershipHeadDashboard = lazy(() =>
  import("./components/dashboard/businessDealershipHead.jsx")
);

// const AttendanceSheet = lazy(() => import("./components/user/AttendanceSheet"));
const RtoTMWiseDashboard = lazy(() =>
  import("./components/dashboard/rtoTMWiseDashboard.jsx")
);
const RtoDealershipWiseDashboard = lazy(() =>
  import("./components/dashboard/rtoDealershipWiseDashboard.jsx")
);
const TmToDosForm = lazy(() => import("./components/user/TmToDosForm.jsx"));
const AmToDosForm = lazy(() => import("./components/user/AmToDosForm.jsx"));
const ShToDosForm = lazy(() => import("./components/user/ShToDosForm.jsx"));
const BDMToddosForm = lazy(() =>
  import("./components/bharatLoans/BDMTodosForm.jsx")
);
const SBDMToddosForm = lazy(() =>
  import("./components/bharatLoans/SBDMTodosForm.jsx")
);
const BLSHToddosForm = lazy(() =>
  import("./components/bharatLoans/BLSHTodosForm.jsx")
);
const RtoFinancierWiseDoneDashboard = lazy(() =>
  import("./components/dashboard/rtoFinancierWiseDoneDashboard.jsx")
);
const RtoTMWiseDoneDashboard = lazy(() =>
  import("./components/dashboard/rtoTMWiseDoneDashboard.jsx")
);
const RtoDealershipWiseDoneDashboard = lazy(() =>
  import("./components/dashboard/rtoDealershipWiseDoneDashboard.jsx")
);

const TodoCompleteForm = lazy(() =>
  import("./components/user/TodosCompleteForm.jsx")
);

const BharatLoansHome = lazy(() =>
  import("./components/bharatLoans/bharatLoanHomePage.jsx")
);

const ManageBharatLoanAndUsersPage = lazy(() =>
  import("./components/user/ManageBharatLoanAndUsersPage.jsx")
);
const AuctionBackOfficeDashboard = lazy(() =>
  import(
    "./components/auction/AuctionBackOffice/AuctionBackOfficeDashboard.jsx"
  )
);
const AddBharatLoans = lazy(() =>
  import("./components/bharatLoans/addBharatLoans.jsx")
);
const EditBharatLoans = lazy(() =>
  import("./components/bharatLoans/editBharatLoan.jsx")
);
const BharatLoanTractor = lazy(() =>
  import("./components/bharatLoans/bharatLoanTractor.jsx")
);
const DSALoans = lazy(() => import("./components/dashboard/dsaLoans.jsx"));
const EditDSALoans = lazy(() =>
  import("./components/editApprovalRequest/editDsaLoans.jsx")
);
const EditProcurementViaAuction = lazy(() =>
  import("./components/editApprovalRequest/editProcurementViaAuction.jsx")
);
const EditProcurementViaExchange = lazy(() =>
  import("./components/editApprovalRequest/editProcurementViaExchange.jsx")
);
const EditBharatLoanTractor = lazy(() =>
  import("./components/editApprovalRequest/editBharatLoanTractor.jsx")
);
const EditBharatLoanCV = lazy(() =>
  import("./components/editApprovalRequest/editBharatLoanCV.jsx")
);
const EditBharatLoanCar = lazy(() =>
  import("./components/editApprovalRequest/editBharatLoanCar.jsx")
);
const BharatLoanCar = lazy(() =>
  import("./components/bharatLoans/bharatLoanCar.jsx")
);
const BharatLoanCV = lazy(() =>
  import("./components/bharatLoans/bharatLoanCV.jsx")
);
const ListBharatLoans = lazy(() =>
  import("./components/bharatLoans/listBharatLoans.jsx")
);
const BharatLoanBusinessDevelopmentManagerDashboard = lazy(() =>
  import(
    "./components/bharatLoans/BharatLoanBusinessDevelopmentManagerDashboard.jsx"
  )
);
const BharatLoanRTODashboard = lazy(() =>
  import("./components/bharatLoans/BharatLoanRTODashboard.jsx")
);
const BharatLoanStateDataDashboard = lazy(() =>
  import("./components/bharatLoans/BharatLoanStateDataDashboard.jsx")
);
const BharatLoanBusinessHeadDashboard = lazy(() =>
  import("./components/bharatLoans/BharatLoanBusinessHeadDashboard.jsx")
);
const RiskManagementDashboard = lazy(() =>
  import("./components/bharatLoans/riskManagmentDashboard.jsx")
);
const BharatLoanSeniorBusinessDevelopmentManagerDashboard = lazy(() =>
  import(
    "./components/bharatLoans/BharatLoanSeniorBusinessDevelopmentManagerDashboard.jsx"
  )
);
const BharatLoanStateHeadDashboard = lazy(() =>
  import("./components/bharatLoans/BharatLoanStateHeadDashboard.jsx")
);

const RcPendingFinacierWiseByUserTableCode = lazy(() =>
  import(
    "./components/bharatLoans/rto/RcPendingFinacierWiseByUserTableCode.jsx"
  )
);
const RcPendingFinancierWiseTableCode = lazy(() =>
  import("./components/bharatLoans/rto/RcPendingFinancierWiseTableCode.jsx")
);

const RcPendingTypeOfVehicleByStateAndFinancier = lazy(() =>
  import(
    "./components/bharatLoans/rto/RcPendingTypeOfVehicleByStateAndFinancier.jsx"
  )
);

const RcPendingBDMorSBDMWiseByStateFinancierTypeOfVehicleTableCode = lazy(() =>
  import(
    "./components/bharatLoans/rto/RcPendingBDMorSBDMWiseByStateFinancierTypeOfVehicleTableCode.jsx"
  )
);
const RcPendingAgentWiseByStateUserPhoneTableCode = lazy(() =>
  import(
    "./components/bharatLoans/rto/RcPendingAgentWiseByStateUserPhoneTableCode.jsx"
  )
);
const RcPendingBySubUserTableCode = lazy(() =>
  import("./components/bharatLoans/rto/RcPendingBySubUserTableCode.jsx")
);
const ReleasePendingData = lazy(() =>
  import("./components/Dealerships/ReleasePendingData.jsx")
);
const BankNocData = lazy(() =>
  import("./components/Dealerships/BankNocData.jsx")
);
const LoansPendingData = lazy(() =>
  import("./components/bharatLoans/LoansPending/LoansPendingData.jsx")
);

const BackOfficeDashboard = lazy(() =>
  import("./common/BackOffice/BackOfficeDashBoard.jsx")
);
const HRDashboard = lazy(() => import("./common/HR/HRDashboard.jsx"));

const RTOTodosFrom = lazy(() => import("./components/RTO/RTOTodosForm.jsx"));
const NegativeRTOList = lazy(() =>
  import("./components/RTO/NegativeRTOList.jsx")
);
const ImplementsHomePage = lazy(() =>
  import("./components/implements/ImplementsHomePage.jsx")
);
const StateOfficeHome = lazy(() =>
  import("./components/implements/stateOffice/StateOfficeHome.jsx")
);
const SefakiHome = lazy(() =>
  import("./components/implements/Sefaki/SefakiHome.jsx")
);
const ImplementsHome = lazy(() =>
  import("./components/implements/Implements/ImplementsHome.jsx")
);
const SparesHome = lazy(() =>
  import("./components/implements/Spares/SparesHome.jsx")
);
const ImplementsNavbar = lazy(() =>
  import("./components/implements/common/ImplementsNavbar.jsx")
);
const DTMNewOrder = lazy(() =>
  import("./components/implements/Orders/DTMNewOrder.jsx")
);
const MTMNewOrder = lazy(() =>
  import("./components/implements/Orders/MTM/MTMNewOrder.jsx")
);

const MyOrdersTable = lazy(() =>
  import("./components/implements/common/MyOrdersTable.jsx")
);
const HeadOfficeDashBoard = lazy(() =>
  import("./components/implements/HeadOffice/HeadOfficeDashBoard.jsx")
);
const WarrantyClaimApprovalPendingPage = lazy(() =>
  import(
    "./components/implements/HeadOffice/WarrantyClaimApprovalPendingPage.jsx"
  )
);
const StrategyHeadDashboard = lazy(() =>
  import("./components/implements/StrategyHead/StrategyHeadDashboard.jsx")
);
const PurchaseOrderApprovalPendingForMT = lazy(() =>
  import(
    "./components/implements/StrategyHead/PurchaseOrderApprovalPendingForMT.jsx"
  )
);
const InvoiceApprovalPendingForMT = lazy(() =>
  import("./components/implements/StrategyHead/InvoiceApprovalPendingForMT.jsx")
);
const OrdersHistory = lazy(() =>
  import("./components/implements/StrategyHead/OrdersHistory.jsx")
);
const ManufacturerInvoiceApprovalPending = lazy(() =>
  import(
    "./components/implements/manufacturer/ManufacturerInvoiceApprovalPending.jsx"
  )
);
const ImplementsManufacturerDashboard = lazy(() =>
  import(
    "./components/implements/manufacturer/ImplementsManufacturerDashboard.jsx"
  )
);
const DispatchOrdersPage = lazy(() =>
  import("./components/implements/manufacturer/DispatchOrdersPage.jsx")
);
const SelectDesignationPage = lazy(() =>
  import("./components/user/SelectDesignationPage.jsx")
);
const StateOfficeMyOrders = lazy(() =>
  import("./components/implements/stateOffice/StateOfficeMyOrders.jsx")
);
const StateOfficeDashboard = lazy(() =>
  import("./components/implements/stateOffice/StateOfficeDashboard.jsx")
);
const StateOfficeInventory = lazy(() =>
  import("./components/implements/stateOffice/StateOfficeInventory.jsx")
);
const MyOrders = lazy(() =>
  import("./components/implements/Orders/MyOrders.jsx")
);

const SuperDealerDashboard = lazy(() =>
  import("./components/implements/SuperDealer/SuperDealerDashboard.jsx")
);
const DTCPlaceNewOrder = lazy(() =>
  import("./components/implements/Orders/DTC/DTCPlaceNewOrder.jsx")
);
const DTSPlaceNewOrder = lazy(() =>
  import("./components/implements/Orders/DTS/DTSPlaceNewOrder.jsx")
);
const SOTSOPlaceNewOrder = lazy(() =>
  import("./components/implements/Orders/SOTSO/SOTSOPlaceNewOrder.jsx")
);

const SuperDealerInventory = lazy(() =>
  import("./components/implements/SuperDealer/SuperDealerInventory.jsx")
);

const SuperDealerSefakiInventory = lazy(() =>
  import("./components/implements/SuperDealer/SuperDealerSefakiInventory.jsx")
);

const SuperDealerWarrantyClaim = lazy(() =>
  import("./components/implements/Warranty/SuperDealerWarrantyClaim.jsx")
);

const ImplementWarrantyClaimPage = lazy(() =>
  import("./components/implements/Warranty/ImplementWarrantyClaimPage.jsx")
);

// implements Reports

const ImplementsReportsPage = lazy(() =>
  import("./components/implements/Reports/ImplementsReportsPage.jsx")
);

//financier schemes
const FinancierSchemesHome = lazy(() =>
  import("./components/financier/FinancierSchemesHome.jsx")
);
//financier Email
const FinanciersEmailList = lazy(() =>
  import("./components/financier/FinanciersEmailList.jsx")
);
//Manage Leaves
const ManageLeaveRequest = lazy(() =>
  import("./components/Leaves/ManageLeaveRequest.jsx")
);
//Manage Budget
const Budgeting = lazy(() => import("./components/Budget/Budgeting.jsx"));

const AuctionBuyerDashboard = lazy(() =>
  import("./components/auction/AuctionBuyer/AuctionBuyerDashboard.jsx")
);
const AssetsListOfAuctionForBondAndKYC = lazy(() =>
  import(
    "./components/auction/AuctionBuyer/AssetsListOfAuctionForBondAndKYC.jsx"
  )
);
const AssetsListOfAuctionForPaymentPending = lazy(() =>
  import(
    "./components/auction/AuctionBuyer/AssetsListOfAuctionForPaymentPending.jsx"
  )
);
const MyWinsAssetsList = lazy(() =>
  import("./components/auction/AuctionBuyer/MyWinsAssetsList.jsx")
);
const AssetsListOfAuctionForReleaseAndBankNOC = lazy(() =>
  import(
    "./components/auction/AuctionRepresentative/AssetsListOfAuctionForReleaseAndBankNOC.jsx"
  )
);
const ApprovedRejectedDealershipList = lazy(() =>
  import(
    "./components/bharatLoans/DealershipApproval/ApprovedRejectedDealershipList.jsx"
  )
);
const OptimizingFarmMechanization = lazy(() =>
  import("./common/mechanization/OptimizingFarmMechanization.jsx")
);
const CropsWiseEquipmentsList = lazy(() =>
  import("./common/mechanization/CropsWiseEquipmentsList.jsx")
);
const MechanizationHome = lazy(() =>
  import("./common/mechanization/MechanizationHome.jsx")
);
const BharatLoanDealershipHoldAmountList = lazy(() =>
  import(
    "./components/bharatLoans/DealershipApproval/BharatLoanDealershipHoldAmountList.jsx"
  )
);
const ManageYards = lazy(() => import("./components/Yard/ManageYards.jsx"));
const YardOwnerHome = lazy(() =>
  import("./components/YardOwner/YardOwnerHome.jsx")
);
const YardOwnerStockInwardListPending = lazy(() =>
  import("./components/YardOwner/YardOwnerStockInwardListPending.jsx")
);
const YardOwnerDashboard = lazy(() =>
  import("./components/YardOwner/YardOwnerDashboard.jsx")
);
const YardOwnerStockInwardConfirmationPending = lazy(() =>
  import("./components/YardOwner/YardOwnerStockInwardConfirmationPending.jsx")
);

//Manage Auction Users

function App() {
  const token = localStorage.getItem("refreshToken");
  let user;
  let user_role;
  if (token) {
    user = jwt(token);
    user_role = localStorage.getItem("user_role");
  }

  const history = useHistory();
  const previousPathRef = useRef(history.location.pathname); // Initialize with the current path

  useEffect(() => {
    // Listen for history changes
    const unlisten = history.listen((location, action) => {
      console.log("Previous Path:", previousPathRef.current); // Log the previous path
      previousPathRef.current = history.location.pathname; // Update with the last known path
    });

    // Clean up the listener on component unmount
    return () => {
      unlisten();
    };
  }, [history]);

  console.log(previousPathRef.current, history.location.pathname);

  // useEffect(() => {
  //   if (token) {
  //     let unsub;
  //     async function GetUserStatus() {
  //       const docRef = doc(db, "block_users", user.phone);
  //       const docSnap = await getDoc(docRef);
  //       if (docSnap.exists()) {
  //         // console.log("Document data:", docSnap.data());
  //         unsub = onSnapshot(doc(db, "block_users", user.phone), (doc) => {
  //           const data = doc.data();
  //           if (data.is_blocked) {
  //             // alert('Your account has been blocked by admin');
  //             localStorage.clear();
  //             window.location.reload();
  //           }
  //         });
  //       }
  //     }
  //     GetUserStatus();

  //     return () => {
  //       unsub();
  //     };
  //   }
  // }, []);

  let designationNameAuctionBuyer = window.location.pathname
    ?.split(":")[1]
    ?.split("&")[1]
    ?.split("=")[1];

  const noNavbarURLs = [
    "/optimizing-farm-mechanization",
    "/crop-specific-data",
    "/mechanization-home",
  ];

  function RenderNavbar() {
    console.log(window.location.pathname);
    if (
      designationNameAuctionBuyer === "auctionBuyer" ||
      noNavbarURLs.includes(window.location.pathname)
    ) {
      return <></>;
    }
    if (!user) {
      return <LoginNavbar />;
    } else if (!!user && user_role === "Super Admin") {
      return <SuperAdminNavbar />;
    } else if (!!user && user_role === "Developer") {
      return <Navbar />;
    } else if (
      !!user &&
      (user_role === "Franchise Owner(implement-dealer)" ||
        user_role === "Head Office" ||
        user_role === "State Office" ||
        user_role === "Manufacturer" ||
        user_role === "Strategy Head")
    ) {
      return <ImplementsNavbar />;
    } else if (!!user && user_role === "Auction Demo User") {
      return <ADUNavbar />;
    } else if (!!user && user_role === "Yard Owner") {
      return <YardOwnerNavbar />;
    } else {
      return <UserNavbar />;
    }
  }

  return (
    <div className=" pb-4" style={{ maxHeight: "100dvh", overflowY: "auto" }}>
      <Router>
        <Suspense
          fallback={
            <div className="d-flex justify-content-center align-items-center">
              Loading...
            </div>
          }
        >
          {/* {!user ? <LoginNavbar /> : <Navbar />} */} {RenderNavbar()}
          <Switch>
            <Route
              exact
              path="/sign-in"
              render={() => {
                if (!user) {
                  return <Login />;
                } else if (
                  !!user &&
                  (user_role === "Super Admin" ||
                    user_role === "Developer" ||
                    user_role === "Business Analyst")
                ) {
                  return <Redirect to="/" />;
                } else {
                  if (!!user && user_role === "Territory Manager")
                    return (
                      <Redirect
                        to={
                          "/tmDashboard:" + user.phone + "&state=" + user.state
                        }
                      />
                    );
                  if (!!user && user_role === "Business Analyst")
                    return (
                      <Redirect
                        to={
                          "/tmDashboard:" + user.phone + "&state=" + user.state
                        }
                      />
                    );
                  else if (!!user && user_role === "Auction Representative")
                    return (
                      <Redirect
                        to={
                          "/auction_representative:" +
                          user.phone +
                          "&state=" +
                          user.state
                        }
                      />
                    );
                  else if (!!user && user_role === "RTO Personnel")
                    return (
                      <Redirect
                        to={
                          "/rtoDashboard:" + user.phone + "&state=" + user.state
                        }
                      />
                    );
                  else if (!!user && user_role === "Account Personnel")
                    return (
                      <Redirect
                        to={
                          "/apDashboard:" + user.phone + "&state=" + user.state
                        }
                      />
                    );
                  else if (!!user && user_role === "Auction Super Admin")
                    return (
                      <Redirect
                        to={
                          "/asaDashboard:" + user.phone + "&state=" + user.state
                        }
                      />
                    );
                  else if (!!user && user_role === "Auction Demo User")
                    return (
                      <Redirect
                        to={
                          "/aduDashboard:" + user.phone + "&state=" + user.state
                        }
                      />
                    );
                  else if (!!user && user_role === `State Head`)
                    return (
                      <Redirect
                        to={
                          "/shDashboard:" + user.phone + "&state=" + user.state
                        }
                      />
                    );
                  else if (!!user && user_role === `Area Manager`)
                    return (
                      <Redirect
                        to={
                          "/amDashboard:" + user.phone + "&state=" + user.state
                        }
                      />
                    );
                  else if (!!user && user_role === `Back Office`)
                    return (
                      <Redirect
                        to={
                          "/backOfficeDashboard:" +
                          user.phone +
                          "&state=" +
                          user.state
                        }
                      />
                    );
                  else {
                    return <Redirect to={"/"} />;
                  }
                }
              }}
            />
            {/* <Route exact path="/sign-in" component={Login}/> */}
            {/* <Route exact path="/" component={SuperAdminHome} /> */}
            <ChangeRoute exact path="/" />
            <UserRoute exact path="/coming-soon" component={ComingSoonPage} />
            <SuperAdminRoute
              exact
              path="/superadminhome:id"
              component={SuperAdminHome}
            />
            {/* <RoleRoute
            exact
            path="/superadminhome:id"
            role={user?.role}
            component={BusinessAnalystDashboard}
          /> */}
            <SuperAdminRoute exact path="/budget-home" component={Budgeting} />
            <SuperAdminRoute
              exact
              path="/business_analyst:id"
              component={BusinessAnalystDashboard}
            />
            <SuperAdminRoute exact path="/logout" component={Logout} />
            <SuperAdminRoute exact path="/super-admin-home">
              <SuperAdminHome name="Entity" />
            </SuperAdminRoute>
            {/* User URLS */}
            <SuperAdminRoute
              exact
              path="/user/user-home"
              component={UserHome}
            />
            <SuperAdminRoute exact path="/user/states" component={State} />
            <SuperAdminRoute exact path="/user/district" component={District} />
            <SuperAdminRoute exact path="/user/tehsil" component={Tehsil} />
            <SuperAdminRoute exact path="/user/cluster" component={Cluster} />
            <SuperAdminRoute
              exact
              path="/user/exchange-dealer-code"
              component={ExchangeDealerCode}
            />
            <SuperAdminRoute
              exact
              path="/user/commision-agents"
              component={CommisionAgents}
            />
            <SuperAdminRoute
              exact
              path="/view-user"
              component={ViewEditUsers}
            />
            <SuperAdminRoute
              exact
              path="/addEdit-user"
              component={AddEditUser}
            />
            <SuperAdminRoute exact path="/add-user" component={AddUser} />
            <SuperAdminRoute exact path="/edit-user/:id" component={EditUser} />
            <SuperAdminRoute
              exact
              path="/user/change-password"
              component={ChangePassword}
            />
            <SuperAdminRoute
              exact
              path="/manage-yard"
              component={ManageYards}
            />
            <SuperAdminRoute
              exact
              path="/user/hierarchy"
              component={UserHierarchy}
            />
            <SuperAdminRoute
              exact
              path="/user/allow-mark-attendance"
              component={AllowToMarkAttendanceTable}
            />
            {/* Tractorpoint URLS */}
            <SuperAdminRoute
              exact
              path="/tractorpoint/tp-home"
              component={TractorpointHome}
            />
            <SuperAdminRoute
              exact
              path="/tractorpoint/add-tp"
              component={AddTractorpoint}
            />
            <SuperAdminRoute
              exact
              path="/tractorpoint/list-tp"
              component={ListTractorpoint}
            />
            <SuperAdminRoute
              exact
              path="/tractorpoint/financier-scheme"
              component={FinancierSchemesHome}
            />
            <SuperAdminRoute
              exact
              path="/tractorpoint/financier-emails/:financier_shorthand"
              component={FinanciersEmailList}
            />
            <SuperAdminRoute
              exact
              path="/tractorpoint/financier"
              component={Financier}
            />
            {/* Bharat Loans URLS */}
            <SuperAdminRoute
              exact
              path="/bharartLoans/bharatLoans-home"
              component={BharatLoansHome}
            />
            <SuperAdminRoute
              exact
              path="/bharartLoans/list-bharatLoans"
              component={ListBharatLoans}
            />
            <SuperAdminRoute
              exact
              path="/bharartLoans/list-bharatLoans-approved-rejected"
              component={ApprovedRejectedDealershipList}
            />
            <SuperAdminRoute
              exact
              path="/bharartLoans/add-edit-hold-amounts"
              component={BharatLoanDealershipHoldAmountList}
            />
            <SuperAdminRoute
              exact
              path="/bharartLoans/add-bharatLoan"
              component={AddBharatLoans}
            />
            <SuperAdminRoute
              exact
              path="/bharartLoans/edit-bharatLoan/:id"
              component={EditBharatLoans}
            />
            {/* UserLevel URLS */}
            <SuperAdminRoute
              exact
              path="/user/manage-business"
              component={ManageBusiness}
            />
            <SuperAdminRoute
              exact
              path="/user/user-level"
              component={Designation}
            />
            <SuperAdminRoute exact path="/edit-tp/:id" component={EditTP} />
            <SuperAdminRoute
              exact
              path="/tractorpoint/add-make"
              component={AddMake}
            />
            <SuperAdminRoute
              exact
              path="/tractorpoint/add-model"
              component={AddModel}
            />
            {/* Inventory URLS */}
            <SuperAdminRoute
              exact
              path="/add-inventory"
              component={InventoryHome}
            />
            <SuperAdminRoute
              exact
              path="/add-inventory/add-tractor"
              component={AddTractor}
            />
            <SuperAdminRoute
              exact
              path="/add-inventory/edit-tractors"
              component={ListTractors}
            />
            <SuperAdminRoute
              exact
              path="/superAdminDashboard/shDashboard"
              component={level2Dashboard}
            />
            <SuperAdminRoute
              exact
              path="/superAdminDashboard:id"
              component={superAdminDashboard}
            />
            {/* Implements URLS */}
            <SuperAdminRoute
              exact
              path="/implements-home"
              component={ImplementsHomePage}
            />
            <SuperAdminRoute
              exact
              path="/implements/manage-so"
              component={StateOfficeHome}
            />
            <SuperAdminRoute
              exact
              path="/implements/manage-sefaki"
              component={SefakiHome}
            />
            <SuperAdminRoute
              exact
              path="/implements/manage-implements"
              component={ImplementsHome}
            />
            <SuperAdminRoute
              exact
              path="/implements/manage-spares"
              component={SparesHome}
            />
            <SuperAdminRoute
              exact
              path="/implements/dashbord/sales/dealerwisecategorywise:id"
              component={DealerWiseCategoryWiseSalesSummary}
            />
            <SuperAdminRoute
              exact
              path="/implements/dashbord/dealerinventory/ProductWiseInventorySummmary:id"
              component={ProductwiseInventorysummary}
            />
            <SuperAdminRoute
              exact
              path="/implements/dashbord/dealerWarranty/DealerCategoryWiseWarrantySummary:id"
              component={DealerCategoryWiseWarrantySummary}
            />
            <SuperAdminRoute
              exact
              path="/implements/manage-manufacturers"
              component={ManufactureView}
            />
            <SuperAdminRoute
              exact
              path="/implements/manage-category"
              component={CategoryHome}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid-home"
              component={RapidbidHomePage}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-auction"
              component={RapibidAuctionHome}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-auction/edit-auction-home/:id"
              component={RapibidEditAuctionHome}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-auction/manage-view-auction"
              component={RapibidAuctionTabbar}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-auction/manage-edit-auction/:id"
              component={EditAuctionForm}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-auction/manage-add-asset-docs/:id"
              component={AuctionAddDocuments}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-auction/manage-asset-img-add/:id"
              component={AuctionAddImage}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-auction/manage-asset-img-edit/:id"
              component={AuctionEditImage}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-auction/manage-auction-summary/:id"
              component={RapibidAuctionSummary}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-auction/manage-download-docs/:id"
              component={AuctionDownloadDocuments}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-auction/manage-add-auction"
              component={AddAuction}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-subscription"
              component={SubscriptionHomePage}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-refund-approval-pending"
              component={RefundApprovalPendingList}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-user-transaction-history"
              component={RefundTransectionHistory}
            />
            <UserRoute
              exact
              path="/rapidbid/user-transaction-history/:id"
              component={TransactionHistoryForAllUsers}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-users-analysis"
              component={SubscriptionUserListHomePage}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-plan"
              component={PlanHomePage}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-plan/create-plan"
              component={CreatePlans}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-plan/create-plan/:id"
              component={CreatePlans}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-users-analysis/active-user"
              component={ActiveSubscriptionList}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-users-analysis/expired-user"
              component={ExpiredUsersSubscriptionlist}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-plan/view-published_plans"
              component={PublishedPlan}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-user"
              component={RapibidUserList}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-user/add-user/:id"
              component={RapibidAddUser}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-user/add-user"
              component={RapibidAddUser}
            />
            <SuperAdminRoute
              exact
              path="/rapibid/auction/make"
              component={Make}
            />
            <SuperAdminRoute
              exact
              path="/rapibid/auction/model"
              component={Model}
            />
            <SuperAdminRoute
              exact
              path="/rapibid/auction/state"
              component={RapibidStateList}
            />
            <SuperAdminRoute
              exact
              path="/rapibid/auction/permission"
              component={Permissions}
            />
            <SuperAdminRoute
              exact
              path="/rapibid/auction/user-login-details"
              component={UserLoginDetails}
            />
            <SuperAdminRoute
              exact
              path="/rapidbid/manage-plan/view-draft_plans"
              component={DraftPlan}
            />
            <SuperAdminRoute
              exact
              path="/implements/manage-inventory"
              component={InventoryHomePage}
            />
            <SuperAdminRoute
              exact
              path="/subscription-home"
              component={SubscriptionHome}
            />
            <SuperAdminRoute
              exact
              path="/subscription/manage-plan"
              component={SubscriptionPlanHomePage}
            />
            <SuperAdminRoute
              exact
              path="/subscription/manage-plan/create-plan"
              component={SubscriptionCreatePlans}
            />
            <SuperAdminRoute
              exact
              path="/subscription/manage-plan/create-plan/:id"
              component={SubscriptionCreatePlans}
            />
            <SuperAdminRoute
              exact
              path="/subscription/manage-plan/view-published_plans"
              component={SubscriptionPublishedPlan}
            />
            <SuperAdminRoute
              exact
              path="/subscription/manage-plan/view-draft_plans"
              component={SubscriptionDraftPlan}
            />
            <SuperAdminRoute
              exact
              path="/subscription/manage-users-analysis"
              component={SubscriptionUserListHomePage}
            />
            <SuperAdminRoute
              exact
              path="/subscription/manage-refund-approval-pending"
              component={SubscriptionRefundApprovalPendingList}
            />
            <SuperAdminRoute
              exact
              path="/subscription/manage-user-transaction-history"
              component={SubscriptionRefundTransectionHistory}
            />
            <UserRoute
              exact
              path="/subscription/user-transaction-history/:id"
              component={SubscriptionTransactionHistoryForAllUsers}
            />
            <UserRoute
              exact
              path="/subscription/manage-platform-tenure"
              component={PlatformTenureList}
            />
            {/* Operation URLs*/}
            <DeveloperRoute
              exact
              path="/operation/operation-home"
              component={OperationHome}
            />
            <DeveloperRoute
              exact
              path="/operation/add-operation"
              component={AddOperation}
            />
            <DeveloperRoute
              exact
              path="/operation/view-edit-operation"
              component={ViewEditOperation}
            />
            <DeveloperRoute
              exact
              path="/operation/edit-operation"
              component={EditOperation}
            />
            <DeveloperRoute
              exact
              path="/operation/edit-operation-data"
              component={EditOperationData}
            />
            <DeveloperRoute
              exact
              path="/operation/add-activity"
              component={AddActivity}
            />
            <DeveloperRoute
              exact
              path="/operation/view-edit-activity"
              component={ViewEditActivity}
            />
            <DeveloperRoute
              exact
              path="/operation/edit-activity"
              component={EditActivity}
            />
            <DeveloperRoute
              exact
              path="/operation/view-edit-draft-activity"
              component={ViewEditDraftActivity}
            />
            <DeveloperRoute
              exact
              path="/operation/edit-draft-activity"
              component={EditDraftActivity}
            />
            {/* Dashboard URLs*/}
            <UserRoute exact path="/rto-list:id" component={NegativeRTOList} />
            <UserRoute exact path="/dashboard:id" component={Dashboard} />
            <UserRoute exact path="/dashboard/addTask" component={AddTask} />
            <UserRoute
              exact
              path="/dashboard/completedTask:id"
              component={CompletedTask}
            />
            <UserRoute
              exact
              path="/dashboard/completedTask/completedTaskDetailPage:id"
              component={CompletedTaskDetailPage}
            />
            <UserRoute
              exact
              path="/dashboard/completedTask/subOrdinateUserData:id"
              component={SubOrdinateUserData}
            />
            <UserRoute
              exact
              path="/dashboard/milestonesDash:id"
              component={MilestonesDash}
            />
            <UserRoute
              exact
              path="/dashboard/ListDashboardData:id"
              component={ListDashboardData}
            />
            <UserRoute
              exact
              path="/dashboard/procurement-via-auction/:id"
              component={ProcurementViaAuction}
            />
            <UserRoute
              exact
              path="/dashboard/procurement-via-dealership/:id"
              component={ProcurementViaDealership}
            />
            <UserRoute
              exact
              path="/dashboard/procurement-via-commision/:id"
              component={ProcurementViaCommision}
            />
            <UserRoute
              exact
              path="/dashboard/procurement-via-f2b/:id"
              component={ProcurementViaF2B}
            />
            <UserRoute
              exact
              path="/dashboard/non-mt-stock/:id"
              component={lazy(() => import("./components/forms/NonMTStock"))}
            />
            <UserRoute
              exact
              path="/dashboard/sale-stock/:id"
              component={SaleStock}
            />
            <UserRoute
              exact
              path="/dashboard/loan-sale-phase/:id"
              component={LoanSalePhase}
            />
            <UserRoute
              exact
              path="/dashboard/cash-sale-phase/:id"
              component={CashSalePhase}
            />
            <UserRoute exact path="/dashboard/loans/:id" component={Loans} />
            <UserRoute exact path="/tmDashboard:id" component={tmDashboard} />
            <UserRoute exact path="/rtoDashboard:id" component={rtoDashboard} />
            <UserRoute exact path="/apDashboard:id" component={apDashboard} />
            <UserRoute exact path="/asaDashboard:id" component={ASAHome} />
            <UserRoute
              exact
              path="/asaDashboard/dashboard_home:id"
              component={ASADashboard}
            />
            <UserRoute
              exact
              path="/asaDashboard/auction_home:id"
              component={MeratractorHome}
            />
            <UserRoute
              exact
              path="/asaDashboard/user-management-home:id"
              component={ASAUserHome}
            />
            <UserRoute
              exact
              path="/asaDashboard/auction_dashboard:id"
              component={LiveAuctionDashboardASA}
            />
            <UserRoute
              exact
              path="/asaDashboard/user_home/add-edit-subscriptions"
              component={UnifiedUsersList}
            />
            <UserRoute
              exact
              path="/asaDashboard/user_home/edit-users"
              component={ASAViewEditUserList}
            />
            <UserRoute
              exact
              path="/asaDashboard/edit-user/:id"
              component={ASAEditUser}
            />
            <UserRoute exact path="/aduDashboard:id" component={ADUDashboard} />
            <UserRoute
              exact
              path="/stockWishList/:id"
              component={WishListCode}
            />
            <UserRoute
              exact
              path="/shDashboard:id"
              component={StateHeadDashboard}
            />
            <UserRoute
              exact
              path="/stateDashboard:id"
              component={StateWiseDataDashboard}
            />
            <UserRoute
              exact
              path="/bdhDashboard:id"
              component={BusinessDealershipHeadDashboard}
            />
            <UserRoute exact path="/amDashboard:id" component={AMDashboard} />
            <UserRoute
              exact
              path="/AuctionAdminSHDashboard:id"
              component={AuctionAdminSHDashboard}
            />
            <UserRoute
              exact
              path="/auction_representative:id"
              component={arDashboard}
            />
            <UserRoute
              exact
              path="/auction_representative/dashboard_home:id"
              component={ARDashboard}
            />
            <UserRoute
              exact
              path="/auction_representative/auction_dashboard:id"
              component={LiveAuctionDashboard}
            />
            <UserRoute
              exact
              path="/auction_back_office/auction_dashboard:id"
              component={LiveAuctionDashboardBackOffice}
            />
            <UserRoute
              exact
              path="/live_auction_dashboard"
              component={AuctionAssetsData}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/modify"
              component={EditAuctionHome}
            />
            <UserRoute
              exact
              path="/auction_representative/auction_home/edit"
              component={AddAuctionMeratractor}
            />
            <UserRoute
              exact
              path="/auction_representative/user_home:id"
              component={ARUserHome}
            />
            <UserRoute
              exact
              path="/auction_representative/user_home/add-user"
              component={AddEditOrViewUserByAuctionRepresentative}
            />
            <UserRoute
              exact
              path="/meratractor/wallet-home"
              component={WalletHome}
            />
            <UserRoute
              exact
              path="/meratractor/auction"
              component={AddAuctionMeratractor}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/add_assets"
              component={AddAsset}
            />
            <UserRoute
              exact
              path="/auction_representative/meratractor/auction-home"
              component={ARAuctionHome}
            />
            <UserRoute
              exact
              path="/auction_representative/auction_home/auction"
              component={ARViewAuction}
            />
            <UserRoute
              exact
              path="/meratractor/auction/update-multiple-assets-start-price"
              component={UpdateMultipleAssetsStartPrice}
            />
            <UserRoute
              exact
              path="/arDashboard/approvalpending/AssetListForApprovalPending:id"
              component={AssetListForApprovalPending}
            />
            <UserRoute
              exact
              path="/arDashboard/AuctionReport/AssetListForAuctionReport:id"
              component={AssetListForAuctionReport}
            />
            <UserRoute
              exact
              path="/arDashboard/paymentPending/AssetListForPaymentPending:id"
              component={AssetListForPaymentPending}
            />
            <UserRoute
              exact
              path="/rtoFinancierWiseDashboard:id"
              component={rtoFinancierWiseDashboard}
            />
            <UserRoute
              exact
              path="/rtoTMWiseDashboard:id"
              component={RtoTMWiseDashboard}
            />
            <UserRoute
              exact
              path="/rtoDealerWiseDashboard:id"
              component={RtoDealershipWiseDashboard}
            />
            <UserRoute
              exact
              path="/rtoFinancierWiseDoneDashboard:id"
              component={RtoFinancierWiseDoneDashboard}
            />
            <UserRoute
              exact
              path="/rtoTMWiseDoneDashboard:id"
              component={RtoTMWiseDoneDashboard}
            />
            <UserRoute
              exact
              path="/rtoDealerWiseDoneDashboard:id"
              component={RtoDealershipWiseDoneDashboard}
            />
            <UserRoute
              exact
              path="/rtoStateWiseDashboard:id"
              component={RtoDashboardCode}
            />
            <UserRoute
              exact
              path="/AuctionDashboard:id"
              component={AuctionDashboard}
            />
            <UserRoute exact path="/tmToDosForm" component={TmToDosForm} />
            <UserRoute exact path="/amTodosForm" component={AmToDosForm} />
            <UserRoute exact path="/shToDosForm" component={ShToDosForm} />
            <UserRoute exact path="/Profile/:id" component={UserProfile} />
            <UserRoute
              exact
              path="/financierWiseAM:id"
              component={FinancierWiseAM}
            />
            <UserRoute
              exact
              path="/rcLimitCaseAMWiseTMTable:id"
              component={RCLimitCaseAMWiseTMTable}
            />
            <UserRoute
              exact
              path="/nameTransferCaseAMWiseTMTable:id"
              component={NameTransferCaseAMWiseTMTable}
            />
            <UserRoute
              exact
              path="/nameTransferTMWiseDealership:id"
              component={NameTransferTMWiseDealership}
            />
            <UserRoute
              exact
              path="/rcLimitTMWiseDealershipTable:id"
              component={RCLimitTMWiseDealershipTable}
            />
            <UserRoute
              exact
              path="/TodoCompleteForm/:id"
              component={TodoCompleteForm}
            />
            {/* <Route path="/pdf" component={PdfView} /> */}{" "}
            {/* Reports URLs*/}
            <UserRoute
              exact
              path="/dealership-reports:id"
              component={DealershipReportsHome}
            />
            <UserRoute
              exact
              path="/reports:id"
              component={SuperAdminRTOReportTab}
            />
            <UserRoute
              exact
              path="/bl-reports:id"
              component={BharatLoanReport}
            />
            {/* Bharat loans URLs*/}
            <UserRoute
              exact
              path="/bharat-loan-business-development-manager-Dashboard:id"
              component={BharatLoanBusinessDevelopmentManagerDashboard}
            />
            <UserRoute
              exact
              path="/bharat-loan-rto-Dashboard:id"
              Ī
              component={BharatLoanRTODashboard}
            />
            <UserRoute
              exact
              path="/bharat-loan-business-head-Dashboard:id"
              component={BharatLoanBusinessHeadDashboard}
            />
            <UserRoute
              exact
              path="/bharat-loan-rc-pending-financierwise-by-user:id"
              component={RcPendingFinacierWiseByUserTableCode}
            />
            <UserRoute
              exact
              path="/bharat-loan-rc-pending-financierwise-by-state:id"
              component={RcPendingFinancierWiseTableCode}
            />
            <UserRoute
              exact
              path="/bharat-loan-rc-pending-typeOfVehicle-by-state-and-financier:id"
              component={RcPendingTypeOfVehicleByStateAndFinancier}
            />
            <UserRoute
              exact
              path="/bharat-loan-rc-pending-bdmOrsbdmwise-by-state-financier-typeOfVehicle:id"
              component={
                RcPendingBDMorSBDMWiseByStateFinancierTypeOfVehicleTableCode
              }
            />
            <UserRoute
              exact
              path="/bharat-loan-rc-pending-dealershipwise-by-state-userPhone:id"
              component={RcPendingAgentWiseByStateUserPhoneTableCode}
            />
            <UserRoute
              exact
              path="/bharat-loan-rc-pending-by-subUser:id"
              component={RcPendingBySubUserTableCode}
            />
            <UserRoute
              exact
              path="/bharat-loan-stateDashboard:id"
              component={BharatLoanStateDataDashboard}
            />
            <UserRoute
              exact
              path="/risk-management-dashbaord:id"
              component={RiskManagementDashboard}
            />
            <UserRoute
              exact
              path="/bharat-loan-senior-business-development-manager-Dashboard:id"
              component={BharatLoanSeniorBusinessDevelopmentManagerDashboard}
            />
            <UserRoute
              exact
              path="/bharat-loan-state-head:id"
              component={BharatLoanStateHeadDashboard}
            />
            <UserRoute
              exact
              path="/bharartLoans/bharat-loan-cv:id"
              component={BharatLoanCV}
            />
            <UserRoute
              exact
              path="/bharartLoans/bharat-loan-tractor:id"
              component={BharatLoanTractor}
            />
            <UserRoute
              exact
              path="/dsaLoans/dsa-loans-tractor:id"
              component={DSALoans}
            />
            <UserRoute
              exact
              path="/editApproval/edit-dsa-loans-tractor:id"
              component={EditDSALoans}
            />
            <UserRoute
              exact
              path="/editApproval/procurement-via-auction/:id"
              component={EditProcurementViaAuction}
            />
            <UserRoute
              exact
              path="/editApproval/procurement-via-dealership/:id"
              component={EditProcurementViaExchange}
            />
            <UserRoute
              exact
              path="/editApproval/edit-bharat-loan-tractor:id"
              component={EditBharatLoanTractor}
            />
            <UserRoute
              exact
              path="/editApproval/edit-bharat-loan-cv:id"
              component={EditBharatLoanCV}
            />
            <UserRoute
              exact
              path="/editApproval/edit-bharat-loan-car:id"
              component={EditBharatLoanCar}
            />
            <UserRoute
              exact
              path="/bharartLoans/bharat-loan-car:id"
              component={BharatLoanCar}
            />
            <UserRoute
              exact
              path="/bl-bdm-todos-form"
              component={BDMToddosForm}
            />
            <UserRoute
              exact
              path="/bl-sbdm-todos-form"
              component={SBDMToddosForm}
            />
            <UserRoute
              exact
              path="/bl-sh-todos-form"
              component={BLSHToddosForm}
            />
            <UserRoute
              exact
              path="/release-pending-data:id"
              component={ReleasePendingData}
            />
            <UserRoute
              exact
              path="/loans-pending-data:id"
              component={LoansPendingData}
            />
            <UserRoute exact path="/bank-noc-data:id" component={BankNocData} />
            <UserRoute
              exact
              path="/rcdetailsdata:id"
              component={RCDataDetails}
            />
            <UserRoute
              exact
              path="/RapibidAuctionApproval:id"
              component={RapibidAuctionApproval}
            />
            <UserRoute exact path="/rto-todos" component={RTOTodosFrom} />
            {/* <UserRoute exact path="/tm-reports:id" component={TMReports} />
                                                                                                                                                                                                                                              <UserRoute exact path="/am-reports:id" component={AMReports} /> */}
            <UserRoute
              exact
              path="/backOfficeDashboard:id"
              component={BackOfficeDashboard}
            />
            <UserRoute exact path="/hrDashboard:id" component={HRDashboard} />
            {/* Implements Routes */}
            <UserRoute
              exact
              path="/implements/manage-inventory/MTInventroy"
              component={MTInventroy}
            />
            <UserRoute
              exact
              path="/implements/manage-inventory/DelearInventory"
              component={DelearInventory}
            />
            <UserRoute
              exact
              path="/implements/manage-inventory/SefakiInventory"
              component={SefakiInventory}
            />
            <UserRoute
              exact
              path="/dealer/new-order:id"
              component={DTMNewOrder}
            />
            <UserRoute
              exact
              path="/head-office/new-order:id"
              component={MTMNewOrder}
            />
            <UserRoute
              exact
              path="/head-office/my-orders:id"
              component={MyOrdersTable}
            />
            <UserRoute
              exact
              path="/headOfficeDashboard:id"
              component={HeadOfficeDashBoard}
            />
            <UserRoute
              exact
              path="/head-Office/approve-warranty-claim:id"
              component={WarrantyClaimApprovalPendingPage}
            />
            <UserRoute
              exact
              path="/head-Office/inventory-transfer:id"
              component={SOTSOPlaceNewOrder}
            />
            <UserRoute
              exact
              path="/strategyHeadDashboard:id"
              component={StrategyHeadDashboard}
            />
            <UserRoute
              exact
              path="/strategy-head/mt-orders:id"
              component={PurchaseOrderApprovalPendingForMT}
            />
            <UserRoute
              exact
              path="/strategy-head/mt-invoices:id"
              component={InvoiceApprovalPendingForMT}
            />
            <UserRoute
              exact
              path="/strategy-head/orders-history:id"
              component={OrdersHistory}
            />
            <UserRoute
              exact
              path="/manufacturer/mfg-invoices:id"
              component={ManufacturerInvoiceApprovalPending}
            />
            <UserRoute
              exact
              path="/manufacturerDashboard:id"
              component={ImplementsManufacturerDashboard}
            />
            <UserRoute
              exact
              path="/manufacturer/dispatch-orders:id"
              component={DispatchOrdersPage}
            />
            <UserRoute
              exact
              path="/state-office/my-orders:id"
              component={StateOfficeMyOrders}
            />
            <UserRoute
              exact
              path="/stateOfficeDashboard:id"
              component={StateOfficeDashboard}
            />
            <UserRoute
              exact
              path="/stateOfficeInventory:id"
              component={StateOfficeInventory}
            />
            <UserRoute
              exact
              path="/superDealerDashboard:id"
              component={SuperDealerDashboard}
            />
            <UserRoute exact path="/dealer/my-orders:id" component={MyOrders} />
            <UserRoute
              exact
              path="/dealer/sell-to-sefaki:id"
              component={DTSPlaceNewOrder}
            />
            <UserRoute
              exact
              path="/dealer/sell:id"
              component={DTCPlaceNewOrder}
            />
            <UserRoute
              exact
              path="/dealer/my-inventory:id"
              component={SuperDealerInventory}
            />
            <UserRoute
              exact
              path="/dealer/sefaki-inventory:id"
              component={SuperDealerSefakiInventory}
            />
            <UserRoute
              exact
              path="/dealer/warranty-claim:id"
              component={SuperDealerWarrantyClaim}
            />
            <UserRoute
              exact
              path="/implement-claim-warranty:id"
              component={ImplementWarrantyClaimPage}
            />
            {/* Rapibid URLS */}
            {/* <SuperAdminRoute
              exact
              path="/rapibid/rapibid-home"
              component={RapibidHome}
            /> */}
            <UserRoute exact path="/meratractor" component={MeratractorHome} />
            <UserRoute
              exact
              path="/meratractor/auction-home"
              component={AuctionHome}
            />
            <UserRoute
              exact
              path="/meratractor/wallet/single-user-txn"
              component={SingleUserTransectionHome}
            />
            <UserRoute
              exact
              path="/meratractor/wallet/multi-user-txn"
              component={MultiUserTransactionHome}
            />
            <UserRoute
              exact
              path="/meratractor/wallet/single-transaction"
              component={SingleUserTransaction}
            />
            <UserRoute
              exact
              path="/meratractor/wallet/view-transactions-by-user"
              component={ViewTransactionOfUsers}
            />
            <UserRoute
              exact
              path="/meratractor/wallet/all-user-balance"
              component={UserBalanceList}
            />
            <UserRoute
              exact
              path="/meratractor/state/permission"
              component={MaratractorPermissions}
            />
            <UserRoute
              exact
              path="/meratractor/wallet/wallet-not-linked"
              component={UserNotLinkedWithWallet}
            />
            <UserRoute
              exact
              path="/meratractor/wallet/upload-transactions-for-one-user"
              component={UploadMultipleTransactionForSingleUser}
            />
            <UserRoute
              exact
              path="/meratractor/wallet/all-transactions"
              component={ViewAllTransactions}
            />
            <UserRoute
              exact
              path="/meratractor/wallet/upload-multiple-transactions"
              component={UploadMultipleTransaction}
            />
            <UserRoute
              exact
              path="/meratractor/user/add-user-to-wallet"
              component={AddUserWallet}
            />
            <UserRoute
              exact
              path="/meratractor/user/logout/users"
              component={LogoutUser}
            />
            <UserRoute
              exact
              path="/meratractor/financiers-section"
              component={FinancierHome}
            />
            <UserRoute
              exact
              path="/meratractor/manage-financiers"
              component={ManageFinancier}
            />
            <UserRoute
              exact
              path="/meratractor/manage-segments"
              component={ManageSegment}
            />
            <UserRoute
              exact
              path="/meratractor/manage-states"
              component={ManageState}
            />
            <UserRoute
              exact
              path="/meratractor/manage-brands"
              component={ManageBrand}
            />  
             <UserRoute
              exact
              path="/meratractor/manage-brands-states"
              component={ManageBrandStates}
            />
            <UserRoute
              exact
              path="/meratractor/segments-section"
              component={SegmentHome}
            />
            <UserRoute
              exact
              path="/meratractor/states-section"
              component={StateHome}
            />
            <UserRoute
              exact
              path="/meratractor/brands-section"
              component={BrandHome}
            />
            <UserRoute
              exact
              path="/meratractor/auction/view-auction"
              component={ViewAuction}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/modify"
              component={EditAuctionHome}
            />
            <UserRoute
              exact
              path="/meratractor/auction/add-auction"
              component={AddAuctionMeratractor}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/edit"
              component={AddAuctionMeratractor}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/add_assets"
              component={AddAsset}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/upload_single_asset"
              component={SingleUpload}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/upload_multiple_assets"
              component={UploadMultipleFile}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/view/assets"
              component={ViewAuctionAsset}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/modify/upload_asset_images"
              component={AddAssetImage}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/modify/edit/asset"
              component={EditAssetDetails}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/modify/view/asset_images"
              component={EditAssetImage}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/modify/view/asset_summary"
              component={AssetSummary}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/auction_summary"
              component={AuctionSummary}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/acr"
              component={AuctionACR}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/view/view_auction_autobid"
              component={AutobidData}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/upload_asset_excel"
              component={UploadImageExcel}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/modify/asset"
              component={ModifyAssets}
            />
            {/* <SuperAdminRoute
              exact
              // path="/meratractor/auctions/modify/asset:id"
              path="/meratractor/auctions/modify/asset"
              component={ModifyAssets}
            /> */}
            {/* <SuperAdminRoute
            exact
            path="/meratractor/auctions/upload_single_asset"
            component={SingleUpload}
          /> */}
            {/* <SuperAdminRoute
            exact
            path="/meratractor/auctions/upload_multiple_assets"
            component={UploadMultipleFile}
          /> */}
            {/* <SuperAdminRoute
            exact
            path="/meratractor/auctions/view/assets"
            component={ViewAuctionAsset}
          /> */}
            <UserRoute
              exact
              path="/meratractor/auction/search-asset-images"
              component={SearchAssetImage}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/modify/upload_asset_images"
              component={AddAssetImage}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/modify/edit/asset"
              component={EditAssetDetails}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/modify/view/asset_images"
              component={EditAssetImage}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/modify/view/asset_summary"
              component={AssetSummary}
            />
            <UserRoute
              exact
              path="/meratractor/auctions/auction_summary"
              component={AuctionSummary}
            />
            <SuperAdminRoute
              exact
              path="/meratractor/auctions/acr"
              component={AuctionACR}
            />
            <SuperAdminRoute
              exact
              path="/meratractor/auctions/view/view_auction_autobid"
              component={AutobidData}
            />
            <SuperAdminRoute
              exact
              path="/meratractor/auctions/upload_asset_excel"
              component={UploadImageExcel}
            />
            <SuperAdminRoute
              exact
              path="/meratractor/auctions/asset-status:id"
              component={AuctionAssetStatus}
            />
            <SuperAdminRoute
              exact
              path="/rapibid/rapibid-reports"
              component={RapibidReports}
            />
            <SuperAdminRoute
              exact
              path="/rapibid/rapibid-dashboard"
              component={RapibidDashboard}
            />
            {/* Implements Reports  */}
            <UserRoute
              exact
              path="/implements-reports:id"
              component={ImplementsReportsPage}
            />
            {/* leaves  */}
            <UserRoute
              exact
              path="/manage-leaves"
              component={ManageLeaveRequest}
            />
            {/* Select Designation  */}
            <UserRoute
              exact
              path="/select-designation"
              component={SelectDesignationPage}
            />
            {/* Account Personnel  */}
            <UserRoute
              exact
              path="/manage-bharat-loan-and-users"
              component={ManageBharatLoanAndUsersPage}
            />
            <UserRoute
              exact
              path="/auction-back-office-dashboard:id"
              component={ActionBackOfficeHome}
            />
            <UserRoute
              exact
              path="/auction_back_office/dashboard_home:id"
              component={AuctionBackOfficeDashboard}
            />
            <UserRoute exact path="/meratractor" component={MeratractorHome} />
            {/* Unified Users  */}
            {/* <UserRoute
            exact
            path="/list-unified-users"
            component={UnifiedUsersList}
          />
          <UserRoute
            exact
            path="/add-unified-user"
            component={AddEditAuctionUsers}
          />
          <UserRoute
            exact
            path="/edit-unified-user/:id"
            component={AddEditAuctionUsers}
          /> */}
            {/* Auction Buyer */}
            <Route
              exact
              path="/auction/auction-buyer-dashboard:id"
              component={AuctionBuyerDashboard}
            />{" "}
            <Route exact path="/view-pdf" component={ViewPDFPage} />{" "}
            <Route
              exact
              path="/auction/auction-wise-assets-list"
              component={AssetsListOfAuctionForBondAndKYC}
            />
            <Route
              exact
              path="/auction/auction-wise-assets-list"
              component={AssetsListOfAuctionForBondAndKYC}
            />
            <Route
              exact
              path="/auction/auction-wise-assets-list-payment-pending"
              component={AssetsListOfAuctionForPaymentPending}
            />
            <Route
              exact
              path="/auction/auction-wise-assets-list-my-wins"
              component={MyWinsAssetsList}
            />
            <UserRoute
              exact
              path="/auction/ar-auction-wise-assets-list"
              component={AssetsListOfAuctionForReleaseAndBankNOC}
            />{" "}
            {/* Yard Owner */}
            <UserRoute
              exact
              path="/yard-owner/yard-owner-home"
              component={YardOwnerHome}
            />{" "}
            <UserRoute
              exact
              path="/yard-owner/stock-inward"
              component={YardOwnerStockInwardListPending}
            />{" "}
            <UserRoute
              exact
              path="/yard-owner/dashboard"
              component={YardOwnerDashboard}
            />{" "}
            <UserRoute
              exact
              path="/yard-owner/mark-stock-inward"
              component={YardOwnerStockInwardConfirmationPending}
            />{" "}
            {/* Callback Pages */}
            <Route exact path="/thank-you-page" component={ThankYouPage} />{" "}
            <Route exact path="/failure-page" component={FailurePage} />{" "}
            {/* Optimzing Farm Mechanization */}
            <Route
              exact
              path="/mechanization-home"
              component={MechanizationHome}
            />{" "}
            <Route
              exact
              path="/crop-specific-data"
              component={CropsWiseEquipmentsList}
            />{" "}
            <Route
              exact
              path="/optimizing-farm-mechanization"
              component={OptimizingFarmMechanization}
            />{" "}
            <Route path="/*" component={NotFound} />{" "}
          </Switch>{" "}
        </Suspense>{" "}
      </Router>
    </div>
  );
}

export default App;

import ErrorHandler from "../ErrorHandler";
import "../../css/common.css";
import { useEffect, useMemo, useState } from "react";

function TableWithProgressBar({
  data,
  columns,
  loading,
  loading_rows = 0,
  loading_cols = 0,
  show,
  no_data_msg = "No Data Found",
  flag,
  ...props
}) {
  const [filteredData, setFilteredData] = useState(data);
  let GrandTotal_asset_Count = 0;
  let GrandTotal_bidders_Count = 0;

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    if (loading) {
      setSortOrder({
        column: null,
        order: null,
      });
    }
  }, [loading]);

  const getDataFromDataKey = (row, data_key) => {
    let keys = data_key.split(".");
    let value = row;
    keys.forEach((key) => {
      value = value[key];
    });
    return value;
  };

  const [sortOrder, setSortOrder] = useState({
    column: null,
    order: null,
  });

  const sortColumn = () => {
    let sorted_data = structuredClone(data);
    if (sortOrder.order === "asc") {
      sorted_data = filteredData.toSorted((a, b) => {
        return a[sortOrder.column.data_key].localeCompare(
          b[sortOrder.column.data_key],
          undefined,
          {
            numeric: true,
          }
        );
      });
    }

    if (sortOrder.order === "desc") {
      sorted_data = filteredData.toSorted((a, b) => {
        return b[sortOrder.column.data_key].localeCompare(
          a[sortOrder.column.data_key],
          undefined,
          {
            numeric: true,
          }
        );
      });
    }
    return sorted_data;
  };

  const sortedData = useMemo(() => {
    let sorted_data = filteredData;
    sorted_data = sortColumn();

    if (loading) return sorted_data;
    if (!sortOrder?.column) return sorted_data;
    if (sorted_data?.length === 0) return sorted_data;

    return sorted_data;
  }, [sortOrder, loading, filteredData, data]);



  try {
    return (
      <>
        <table
          className="table table-striped table-hover"
          style={{ borderRadius: "8px" }}
        >
          <thead
            className=""
            style={{
              backgroundColor: `hsl(var(--border))`,
            }}
          >
            <tr
              className=""
              style={{
                fontSize: "small",
                position: "sticky",
                top: 0, // Change from -3 to 0
                zIndex: 3,
                backgroundColor: "#f8f9fa", // Ensure a background color
              }}
            >
              {columns.map((column, index) => (
                <th
                  key={`thead_${column?.data_key}_${index}`}
                  rowSpan={column?.row_span}
                  colSpan={column?.col_span}
                  className={
                    column?.reset_default_bootstrap_classes_thead
                      ? ""
                      : "text-center align-middle text-sm"
                  }
                  style={{
                    ...column?.style,
                    cursor: column?.sortable ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (
                      column?.sortable &&
                      !loading &&
                      filteredData?.length > 0
                    ) {
                      setSortOrder((prev) => {
                        if (prev?.column?.data_key === column?.data_key) {
                          return {
                            column: column,
                            order: prev.order === "asc" ? "desc" : "asc",
                          };
                        } else {
                          return {
                            column: column,
                            order: "asc",
                          };
                        }
                      });
                    }
                  }}
                >
                  <span>{column?.title} </span>{" "}
                  <span>
                    {sortOrder.column?.data_key === column.data_key ? (
                      sortOrder.order === "asc" ? (
                        <i className="bi bi-arrow-up"></i>
                      ) : (
                        <i className="bi bi-arrow-down"></i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading
              ? Array(loading_rows)
                  .fill(0)
                  .map((v, i) => {
                    return (
                      <tr
                        key={`${i}_table_loading_row`}
                        style={{ cursor: "wait", marginTop: "-2px" }}
                        className=""
                      >
                        {Array(loading_cols)
                          .fill(0)
                          .map((v, j) => {
                            return (
                              <td
                                className=""
                                key={`${j}_table_loading_column`}
                              >
                                <p className="placeholder-glow mb-0">
                                  <span className="placeholder col-12"></span>
                                </p>
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })
              : sortedData &&
                sortedData?.map((row, row_index) => {
                  return (
                    <tr key={`data_row_${row_index}`} className="">
                      {columns.map((column, column_index) => (
                        <td
                          className={
                            column?.reset_default_bootstrap_classes_td
                              ? ""
                              : "text-center align-middle  text-sm"
                          }
                          colSpan={column?.td_col_span}
                          rowSpan={column?.td_row_span}
                          style={column?.td_style}
                          key={`data_row_${row_index}_column_${column_index}`}
                        >
                          {column?.render
                            ? column?.render(row, row_index, column_index)
                            : getDataFromDataKey(row, column?.data_key)}
                        </td>
                      ))}
                    </tr>
                  );
                })}
          </tbody>
          {!loading && sortedData.length > 0 && flag === "OverView" && (
            <tfoot>
              <tr
                style={{
                  fontSize: "small",
                  position: "sticky",
                  bottom: -3,
                  zIndex: 3,
                  backgroundColor: "#f8f9fa",
                }}
              >
                <th colSpan={7}>Total</th>

                <th colSpan={1} className="text-center">
                  {(() => {
                    const GrandTotal_asset_Count = sortedData.reduce(
                      (acc, item) => {
                        const assetCount = Number(item?.asset_count) || 0;
                        return acc + assetCount;
                      },
                      0
                    );

                    const GrandTotal_bidders_Count = sortedData.reduce(
                      (acc, item) => {
                        const biddersCount =
                          Number(item?.participating_bidders_count) || 0; // Use correct key
                        return acc + biddersCount;
                      },
                      0
                    );
                    return `${
                      GrandTotal_bidders_Count && GrandTotal_bidders_Count
                    } / ${GrandTotal_asset_Count && GrandTotal_asset_Count}`;
                  })()}
                </th>
                <th colSpan={1} className="text-center"></th>
                <th colSpan={1} className="text-center">
                  {(() => {
                    let stateWiseBidders = {};
                    let stateWiseSubscribedUsers = {};

                    sortedData.forEach((item) => {
                      const biddersCount =
                        Number(item?.participating_bidders_count) || 0;
                      const subscribedUsers =
                        Number(item?.subscribed_users_count) || 0;

                      if (!stateWiseBidders[item?.state]) {
                        stateWiseBidders[item?.state] = biddersCount;
                      }

                      if (!stateWiseSubscribedUsers[item?.state]) {
                        stateWiseSubscribedUsers[item?.state] = subscribedUsers;
                      }
                    });
                    const GrandTotal_participating_bidders_count =
                      Object.values(stateWiseBidders).reduce(
                        (acc, count) => acc + count,
                        0
                      );

                    const GrandTotal_subscribed_users_count = Object.values(
                      stateWiseSubscribedUsers
                    ).reduce((acc, count) => acc + count, 0);

                    return `${GrandTotal_subscribed_users_count}`;
                  })()}
                </th>
              </tr>
            </tfoot>
          )}
          {!loading && sortedData.length > 0 && flag === "assets" && (
            <tfoot>
              <tr
                style={{
                  fontSize: "small",
                  position: "sticky",
                  bottom: -3,
                  zIndex: 3,
                  backgroundColor: "#f8f9fa",
                }}
              >
                <th colSpan={7}>Total</th>
                <th colSpan={3} className="text-center">
                  {(() => {
                    const GrandTotal_bidders_Count = sortedData.reduce(
                      (acc, item) => {
                        const biddersCount =
                          Number(item?.participating_bidders_count) || 0; // Use correct key
                        return acc + biddersCount;
                      },
                      0
                    );
                    return `${
                      GrandTotal_bidders_Count && GrandTotal_bidders_Count
                    }`;
                  })()}
                </th>
              </tr>
            </tfoot>
          )}
          {!loading && sortedData.length > 0 && flag === "bidders" && (
            <tfoot>
              <tr
                style={{
                  fontSize: "small",
                  position: "sticky",
                  bottom: -3,
                  zIndex: 3,
                  backgroundColor: "#f8f9fa",
                }}
              >
                <th colSpan={3}>Total</th>
                <th colSpan={1} className="text-center">
                  {(() => {
                    const GrandTotal_asset_Count = sortedData.reduce(
                      (acc, item) => {
                        const assetCount =
                          Number(item?.bidded_assets_count) || 0; // Use correct key
                        return acc + assetCount;
                      },
                      0
                    );

                    return `${
                      GrandTotal_asset_Count && GrandTotal_asset_Count
                    }`;
                  })()}
                </th>
                <th colSpan={1} className="text-center"></th>
                <th colSpan={1} className="text-center">
                  {(() => {
                    const losingBidCount = sortedData.reduce((acc, item) => {
                      const assetCount =
                        Number(item?.bidded_assets_count) -
                          Number(item?.total_winning_bid_count) || 0;
                      return acc + assetCount;
                    }, 0);

                    const GrandTotal_total_winning_bid_count =
                      sortedData.reduce((acc, item) => {
                        return (
                          acc + (Number(item?.total_winning_bid_count) || 0)
                        );
                      }, 0);

                    return `${losingBidCount} / ${GrandTotal_total_winning_bid_count} `;
                  })()}
                </th>
              </tr>
            </tfoot>
          )}
        </table>
        {!loading && filteredData.length === 0 && (
          <div className=" text-center mt-text-red-regular">{no_data_msg}</div>
        )}
      </>
    );
  } catch (error) {
    console.error(error);
    return <ErrorHandler error={error} />;
  }
}

export default TableWithProgressBar;

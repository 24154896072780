const toggleDropdown = (key, setDropdowns) => {
  setDropdowns((prev) => {
    if (prev[key]) return { ...prev, [key]: false }; // Close if already open

    return Object.keys(prev).some((k) => prev[k])
      ? { [key]: true } // Close all others and open this one
      : { ...prev, [key]: !prev[key] }; // Just toggle normally
  });
};

export default toggleDropdown;

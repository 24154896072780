import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useCallback, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { errorToast, successToast } from "../../../react-toastfiy/toast";
import auctionService from "../../../services/auction/auction.service";
import CreateBrandStatesModalPopup from "./CreateBrandStatesModalPopup";
import SearchBox from "../../../common/SearchBox";

const ManageBrandStates = () => {
    const token = localStorage.getItem("refreshToken");
    const user = jwtDecode(token);
    const history = useHistory();
    const source = axios.CancelToken.source();
    const [loading, setLoading] = useState(false);
    const [stateData, setStateData] = useState([])
    const [brandStateData, setBrandStateData] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [arrayLength, setArrayLength] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [modal, setModal] = useState(false);

    useEffect(() => {
        document.title = "Manage brand state";
    }, []);

    let initialValue = {
        account_holder_name: "",
        account_number: "",
        bank_name: "",
        branch_name: "",
        brand_id: "",
        brand_name: "",
        ifsc: "",
        no: "",
        state_id: "",
        state_name: "",
        suffix_account_number: "",
        prefix_account_number: "",
        is_acc_no_dynamic:false
    };
    const [modalData, setModalData] = useState(initialValue);

    useEffect(() => {
        try {
            setLoading(true);
            auctionService.GetBrandState(source).then((res) => {
                setLoading(false);
                if (res?.status === 200) {
                    console.log("if")
                    const brand_list = res?.data?.map((val, index) => {
                        return {
                            ...val,
                            no: (currentPage - 1) * itemsPerPage + (index + 1),
                        };
                    });
                    setBrandStateData(brand_list);
                    setFilteredData(brand_list);
                    const lenTrans = res[0]?.brand_states_count;
                    setArrayLength(lenTrans);
                } else {
                    console.log("else")

                    setBrandStateData([]);
                }
            });

        } catch (error) {
            errorToast(error.msg);
        }
    }, [currentPage]);
    useEffect(() => {
        try {
            auctionService.GetAllBrand().then((res) => {
                if (res?.status === 200) {
                    const brand_list = res?.data?.map((val, index) => ({
                        label: val?.name,
                        value: val
                    }));
                    setBrandList(brand_list);
                } else {
                    setBrandList([]);
                }
            });
            auctionService.GetAllAuctionState().then((res) => {
                if (res.status === 200) {
                    const state_list = res?.data?.map((val) => ({ value: val, label: val?.name }))
                 
                    setStateData(state_list);
                    

                } else { errorToast(res?.msg) }
            })
        } catch (error) {
            errorToast(error?.msg)
        }
    }, [])

    const handleDelete = (e, rowData) => {
        e.preventDefault();
        const form_data = new FormData();
        form_data.append("brand_id", rowData.brand_id);
        form_data.append("state_id", rowData.state_id);
        Swal.fire({
            title: "Are you sure?",
            html: `<p>You want to delete this Brand State!</p>`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                try {
                    const response = await auctionService.DeleteBrandState(form_data, source);
                    console.log(response?.status)
                    if (response?.status === 200) {
                        window.location.reload();
                        successToast(response?.msg);
                    } else {
                        errorToast(response?.msg);
                    }
                } catch (error) {
                    console.log(error);
                    errorToast(error?.message);
                }
            },
        });
    };

    console.log({ filteredData });
    return (
      <>
        <div className="container-fluid shadow-sm">
          <div className="container">
            <div className="row">
              <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.name}</h1>
                  </div>
                  <div className="col-12">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12 col-md-6 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-6 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-none d-lg-block d-xl-block">
                <div className="row">
                  <div className="col-12 col-md-8 mt-4 ">
                    <h2 className="m4">{user.name}</h2>
                  </div>
                  <div className="col-12 col-md-4 mt-4 mb-4">
                    <h1 style={{ fontSize: "28px" }}>{user.role}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container responsive mt-2 mb-3 ">
          <div className="row mt-2">
            <div className="d-flex flex-wrap justify-content-between no-wrap mb-2">
              <span className="d-flex justify-content-between col-12 col-md-1 col-lg-1">
                <i
                  className="bi bi-arrow-left-circle "
                  onClick={() => history.push("/meratractor")}
                  style={{
                    fontSize: "xx-large",
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>
              </span>
              <div className="col-7 col-md-8 col-lg-8 col-xl-9 mt-2">
                <h4 className="">Manage Brands States</h4>
              </div>
              <div
                className="d-flex justify-content-end col-5 col-md-3 col-lg-3 col-xl-2"
                style={{ marginTop: "5px", marginBottom: "3px" }}
              >
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    setModal(true);
                  }}
                >
                  <span style={{ marginBottom: "1px", marginRight: "3px" }}>
                    +
                  </span>
                  Add Brand States
                </button>
              </div>
            </div>
          </div>
          <div className="col-12" style={{ marginBottom: "4px" }}>
            <SearchBox
              allData={brandStateData}
              setFilteredData={setFilteredData}
            />
          </div>
        </div>
        {modal && (
          <CreateBrandStatesModalPopup
            show={modal}
            state={stateData}
            brand={brandList}
            setModal={setModal}
            data={modalData}
            initialValue={initialValue}
            setdata={setModalData}
            setLoading={setLoading}
            itemsPerPage={itemsPerPage}
            setBrandStateData={setBrandStateData}
            setFilteredData={setFilteredData}
            onHide={() => {
              setModal(false);
              setModalData(initialValue);
            }}
          />
        )}

        {loading ? (
          <div
            className="d-flex justify-content-center container"
            style={{ minHeight: "80vh", alignItems: "center" }}
          >
            <ReactLoading
              type={"bubbles"}
              color={"black"}
              height={100}
              width={100}
            />
          </div>
        ) : (
          <>
            <div
              className="container  table-responsive mt-2 text-center"
              style={{ minHeight: "5vh", maxHeight: "80vh" }}
            >
              <table className="table table-hover table-bordered">
                <thead className="thead-dark">
                  <tr
                    className="text-center"
                    style={{
                      fontSize: "small",
                      position: "sticky",
                      top: -2,
                      zIndex: 3,
                    }}
                  >
                    <th>Sr. No</th>
                    <th>Brands</th>
                    <th>State</th>
                    <th>Bank Name</th>
                    <th>Account Holder Name</th>
                    <th>Account No</th>
                    <th>Branch</th>
                    <th>Ifsc</th>
                    <th
                      style={{
                        fontSize: "small",
                        position: "sticky",
                        right: -13,
                        zIndex: 1,
                      }}
                    >
                      Edit/Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                  Object.entries(filteredData) &&
                  Object.entries(filteredData).length === 0 ? (
                    <div className="text-danger mt-2 "></div>
                  ) : (
                    Object.entries(filteredData).map(
                      ([key, rowData], rowIndex) => (
                        <tr>
                          <td>{rowData?.no}</td>
                          <td className="text-align-start">
                            {rowData.brand_name}
                          </td>
                          <td className="text-align-start">
                            {rowData.state_name}
                          </td>
                          <td className="text-align-start">
                            {rowData.bank_name}
                          </td>
                          <td className="text-align-start">
                            {rowData.account_holder_name}
                          </td>
                          <td className="text-align-start">
                            {rowData?.is_acc_no_dynamic
                              ? `${rowData.prefix_account_number}(Loan No.)`
                              : rowData.account_number}
                          </td>
                          <td className="text-align-start">
                            {rowData.branch_name}
                          </td>
                          <td className="text-align-start">{rowData.ifsc}</td>
                          <td
                            style={{
                              fontSize: "small",
                              position: "sticky",
                              right: -13,
                              zIndex: 1,
                              backGround: "shadow-sm",
                            }}
                          >
                            <i
                              className="bi bi-pencil-square mx-3"
                              onClick={() => {
                                setModalData(rowData);
                                setModal(true);
                              }}
                              style={{ cursor: "pointer" }}
                            ></i>
                            <i
                              className="bi bi-trash mx-3"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                handleDelete(e, rowData);
                              }}
                            ></i>
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
              {Object.entries(filteredData) &&
                Object.entries(filteredData).length === 0 && (
                  <div className="text-danger text-center mt-2 ">No Data</div>
                )}
            </div>
          </>
        )}
      </>
    );
};

export default ManageBrandStates;

import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useAutoFetch from "../../hooks/useAutoFetch";
import ErrorHandler from "../../common/ErrorHandler";
import { errorToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";
import toggleDropdown from "../../helpers/functions/toggleDropdown";
import TableWithProgressBar from "../../common/Table/TableWithProgressBar";
import SearchBoxWithCustomQuery from "../../common/SearchBoxWithCustomQuery";
import * as XLSX from "xlsx";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";

const useDetectFirstRender = () => {
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);
  return firstRender;
};

function AuctionOverview() {
  const history = useHistory();
  const tableRef = useRef(null);
  const [allData, setAllData] = useState([]);
  const firstRenders = useDetectFirstRender();

  const [segmentList, setSegmentList] = useState();
  const [fetchDataF, setFetchData] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [financiersList, setFinanciersList] = useState();
  const [dropdowns, setDropdowns] = useState({
    brand_name: false,
    state: false,
    segment: false,
  });
  const [filters, setFilters] = useState({
    brand_name: "",
    state: "",
    segment: "",
  });
  const [statesList, setStatesList] = useState([]);

  useEffect(() => {
    if (firstRenders) {
      localStorage.removeItem("state");
      localStorage.removeItem("segment");
      localStorage.removeItem("brand_name");
      setFilters({
        brand_name: "",
        state: "",
        segment: "",
      });
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoadingData(true);

      const stateName = localStorage.getItem("state") || "";
      const segmentName = localStorage.getItem("segment") || "";
      const financierName = localStorage.getItem("brand_name") || "";

      const form_data = new FormData();
      form_data.append("auction_status", "live");

      const source = axios.CancelToken.source();
      const res = await auctionService.GetLiveAuction(form_data, source);

      if (res?.status === 200) {
        setTimer("30");
        const newRes = res?.data.map((val) => ({
          ...val,
          state: val.state_name,
          segment: val.segment_name,
          auction_end_time: val?.end_date,
        }));

        setLoadingData(false);

        let financiers_list = [];
        let state_list = [];
        let segment_list = [];
        const uniqueFinanciers = new Set();
        const uniqueStates = new Set();
        const uniqueSegments = new Set();

        const filteredRes = newRes.filter(
          (item) =>
            (!financierName || item.brand_name === financierName) &&
            (!stateName || item.state_name === stateName) &&
            (!segmentName || item.segment_name === segmentName)
        );

        newRes.forEach((item) => {
          if (item.brand_name && !uniqueFinanciers.has(item.brand_name)) {
            uniqueFinanciers.add(item.brand_name);
            financiers_list.push({
              label: item.brand_name,
              value: item.brand_name,
            });
          }
          if (item.state_name && !uniqueStates.has(item.state_name)) {
            uniqueStates.add(item.state_name);
            state_list.push({ label: item.state_name, value: item.state_name });
          }
          if (item.segment_name && !uniqueSegments.has(item.segment_name)) {
            uniqueSegments.add(item.segment_name);
            segment_list.push({
              label: item.segment_name,
              value: item.segment_name,
            });
          }
        });

        setFilters((prev) => ({
          ...prev,
          state: stateName || "",
          brand_name: financierName || "",
          segment: segmentName || "",
        }));

        setFinanciersList(
          financiers_list.sort((a, b) => a.label.localeCompare(b.label))
        );
        setStatesList(
          state_list.sort((a, b) => a.label.localeCompare(b.label))
        );
        setSegmentList(
          segment_list.sort((a, b) => a.label.localeCompare(b.label))
        );

        setFilteredData(filteredRes.length ? filteredRes : newRes);
        setAllData(newRes);
      } else {
        setLoadingData(false);

        setFilteredData([]);
        setAllData([]);
        errorToast(res?.msg);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingData(false);
    }
  }, []);

  const { timer, loading, setTimer } = useAutoFetch(fetchData, 30000);

  const memoizedToggleDropdown = useCallback(
    (key) => toggleDropdown(key, setDropdowns),
    [setDropdowns]
  );

  const handleRedirect = (key, value) => {
    if (key === "auction_id") {
      history.push({
        pathname: "/live_auction_dashboard",
        search: `?auction_id=${value?.auction_id}`,
        state: { filteredData: value, flag: "auction_id" },
      });
    } else if (key === "bidded_asset_count") {
      history.push({
        pathname: "/live_auction_dashboard",
        search: `?bidded_asset_count=${value?.auction_id}`,
        state: { filteredData: value, flag: "bidded_asset_count" },
      });
    } else if (key === "asset_count") {
      history.push({
        pathname: "/live_auction_dashboard",
        search: `?asset_count=${value?.auction_id}`,
        state: { filteredData: value, flag: "asset_count" },
      });
    } else if (key === "participating_bidders_count") {
      history.push({
        pathname: "/live_auction_dashboard",
        search: `?participating_bidders_count=${value?.auction_id}`,
        state: { filteredData: value, flag: "participating_bidders_count" },
      });
    } else if (key === "nonparticipating_bidders_count") {
      history.push({
        pathname: "/live_auction_dashboard",
        search: `?nonparticipating_bidders_count=${value?.auction_id}`,
        state: { filteredData: value, flag: "nonparticipating_bidders_count" },
      });
    }
  };

  const table_columns = [
    {
      title: "#",
      data_key: "",
      render: (row, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Auction Id",
      data_key: "auction_id",
      sortable: true,
      render: (row) => {
        return (
          <>
            <span
              style={{ cursor: "pointer" }}
              className=" text-nowrap link-primary text-decoration-none"
              onClick={() => handleRedirect("auction_id", row)}
            >
              {row?.auction_id}
            </span>{" "}
          </>
        );
      },
    },
    {
      title: "Auction end time",
      data_key: "",
      sortable: false,
      render: (row) => {
        return (
          <>
            <span
              style={{ cursor: "pointer", fontWeight: "bold" }}
              className="text-nowrap"
            >
              {row?.remainingTime}
            </span>{" "}
          </>
        );
      },
    },
    {
      title: (
        <>
          State
          {filters?.state !== "" ? (
            <i
              class="bi bi-funnel-fill ms-2"
              style={{
                fontSize: "large",
                color: "black",
                borderRadius: "50%",
                zIndex: 6,
                cursor: "pointer",
              }}
              onClick={() => memoizedToggleDropdown("state")}
            ></i>
          ) : (
            <i
              class="bi bi-funnel ms-2"
              style={{
                fontSize: "large",
                color: "black",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={() => memoizedToggleDropdown("state")}
            ></i>
          )}
          {dropdowns?.state && (
            <div
              className="dropdown-menu show p-2"
              style={{
                position: "absolute",
                top: "100%",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 10,
                width: "200px",
              }}
            >
              <select
                className="form-select"
                value={filters.state}
                onChange={(e) => handleFilterChange("state", e.target.value)}
              >
                <option value="">Select State</option>
                {statesList.map((state, index) => (
                  <option key={index} value={state.label}>
                    {state.label}
                  </option>
                ))}
              </select>
              <button
                className="btn btn-sm  btn-md btn-danger mt-2 w-100"
                onClick={() => clearFilter("state", filters)}
              >
                Clear
              </button>
            </div>
          )}
        </>
      ),
      data_key: "state_name",
      sortable: false,
    },
    {
      title: (
        <>
          Segment
          {filters?.segment !== "" ? (
            <i
              class="bi bi-funnel-fill ms-2"
              style={{
                fontSize: "large",
                color: "black",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={() => memoizedToggleDropdown("segment")}
            ></i>
          ) : (
            <i
              class="bi bi-funnel ms-2"
              style={{
                fontSize: "large",
                color: "black",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={() => memoizedToggleDropdown("segment")}
            ></i>
          )}
          {dropdowns?.segment && (
            <div
              className="dropdown-menu show p-2"
              style={{
                position: "absolute",
                top: "100%",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 10,
                width: "200px",
              }}
            >
              <select
                className="form-select"
                value={filters.segment}
                onChange={(e) => handleFilterChange("segment", e.target.value)}
              >
                <option value="">Select Segment</option>
                {segmentList.map((segment, index) => (
                  <option key={index} value={segment.label}>
                    {segment.label}
                  </option>
                ))}
              </select>
              <button
                className="btn btn-sm  btn-md btn-danger mt-2 w-100"
                onClick={() => clearFilter("segment", filters)}
              >
                Clear
              </button>
            </div>
          )}
        </>
      ),
      data_key: "segment_name",
      sortable: false,
    },

    {
      title: (
        <>
          Financier
          {filters?.brand_name !== "" ? (
            <i
              class="bi bi-funnel-fill ms-2"
              style={{
                fontSize: "large",
                color: "black",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={() => memoizedToggleDropdown("brand_name")}
            ></i>
          ) : (
            <i
              class="bi bi-funnel ms-2"
              style={{
                fontSize: "large",
                color: "black",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={() => memoizedToggleDropdown("brand_name")}
            ></i>
          )}
          {dropdowns?.brand_name && (
            <div
              className="dropdown-menu show p-2"
              style={{
                position: "absolute",
                top: "100%",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 10,
                width: "200px",
              }}
            >
              <select
                className="form-select"
                value={filters.brand_name}
                onChange={(e) =>
                  handleFilterChange("brand_name", e.target.value)
                }
              >
                <option value="">Select Financier</option>
                {financiersList.map((financier, index) => (
                  <option key={index} value={financier.label}>
                    {financier.label}
                  </option>
                ))}
              </select>
              <button
                className="btn btn-sm  btn-md btn-danger mt-2 w-100"
                onClick={() => clearFilter("brand_name", filters)}
              >
                Clear
              </button>
            </div>
          )}
        </>
      ),
      data_key: "brand_name",
      sortable: false,
    },
    {
      title: "No of Assets",
      data_key: "asset_count",
      sortable: false,
    },
    {
      title: "Assets Bidded",
      data_key: "",
      sortable: false,
      render: (row) => {
        return (
          <>
            {row?.bidded_asset_count !== 0 ? (
              <span
                style={{ cursor: "pointer" }}
                className=" text-nowrap link-primary text-decoration-none"
                onClick={() => handleRedirect("bidded_asset_count", row)}
              >
                {row?.bidded_asset_count}
              </span>
            ) : (
              <span className=" text-nowrap  text-decoration-none">
                {row?.bidded_asset_count}
              </span>
            )}
            /
            <span
              style={{ cursor: "pointer" }}
              className=" text-nowrap link-primary text-decoration-none"
              onClick={() => handleRedirect("asset_count", row)}
            >
              {row?.asset_count}
            </span>
          </>
        );
      },
    },
    {
      title: "Avg. Seeling Price",
      data_key: "",
      sortable: false,
      render: (row) => {
        return (
          <>
            <span className=" text-nowrap  text-decoration-none">
              {indianCurrencyFormater(row?.average_selling_price)}
            </span>
          </>
        );
      },
    },
    {
      title: "Bidders Participated",
      data_key: "",
      sortable: false,
      render: (row) => {
        return (
          <>
            {row?.participating_bidders_count !== 0 ? (
              <span
                style={{ cursor: "pointer" }}
                className=" text-nowrap link-primary text-decoration-none"
                onClick={() =>
                  handleRedirect("participating_bidders_count", row)
                }
              >
                {row?.participating_bidders_count}
              </span>
            ) : (
              <span className=" text-nowrap  text-decoration-none">
                {row?.participating_bidders_count}
              </span>
            )}
            /
            <span
              style={{ cursor: "pointer" }}
              className=" text-nowrap link-primary text-decoration-none"
              onClick={() =>
                handleRedirect("nonparticipating_bidders_count", row)
              }
            >
              {row?.subscribed_users_count}
            </span>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setAllData((prevData) =>
        prevData.map((item) => {
          const remainingTime = getRemainingTime(item.auction_end_time);
          return { ...item, remainingTime };
        })
      );
      setFilteredData((prevData) =>
        prevData.map((item) => {
          const remainingTime = getRemainingTime(item.auction_end_time);
          return { ...item, remainingTime };
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getRemainingTime = (endTime) => {
    if (!endTime) return "Invalid Date"; // Handle missing values

    const now = moment();
    const end = moment(endTime, "DD-MM-YYYY HH:mm:ss"); // Specify format explicitly
    if (!end.isValid()) return "Invalid Date"; // Handle parsing errors

    const duration = moment.duration(end.diff(now));

    if (duration.asSeconds() <= 0) return "Expired"; // Show Expired if time is up

    return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
    let updatedFilter = { ...filters, [key]: value };
    setFilters(updatedFilter);

    setDropdowns((prev) => ({
      ...prev,
      [key]: false,
    }));

    localStorage.setItem([key], value);

    const filtered = allData.filter((item) => {
      return (
        (updatedFilter?.brand_name
          ? item?.brand_name === updatedFilter?.brand_name
          : true) &&
        (updatedFilter?.segment
          ? item.segment === updatedFilter?.segment
          : true) &&
        (updatedFilter?.status
          ? item.status === updatedFilter?.status
          : true) &&
        (updatedFilter?.state ? item.state === updatedFilter?.state : true)
      );
    });
    setFilteredData(filtered);
  };

  const clearFilter = (key, filters) => {
    let updatedFilter = { ...filters, [key]: "" };
    setFilters(updatedFilter);
    setDropdowns({
      brand_name: false,
      state: false,
      segment: false,
      status: false,
    });

    localStorage.removeItem([key]);
    const filtered = allData.filter((item) => {
      return (
        (updatedFilter?.brand_name
          ? item?.brand_name === updatedFilter?.brand_name
          : true) &&
        (updatedFilter?.segment
          ? item.segment === updatedFilter?.segment
          : true) &&
        (updatedFilter?.status
          ? item.status === updatedFilter?.status
          : true) &&
        (updatedFilter?.state ? item.state === updatedFilter?.state : true)
      );
    });

    setFilteredData(filtered);
  };

  const handleDownloadExcel = (e, value) => {
    e.preventDefault();

    if (Object.entries(value)?.length > 0) {
      const data = [];
      const columns = [
        "Sr. No.",
        "Auction Id",
        "State",
        "Segment",
        "Financier",
        "No of Assets",
        "Assets Bidded",
        "Avg. Seeling Price",
        "Bidders Participated",
      ];
      data.push(columns);

      let serialNumber = 1;
      let totalAssetsBidded = 0;
      let totalAssetsCount = 0;

      let stateWiseBidders = {};
      let stateWiseSubscribedUsers = {};

      Object.entries(value).forEach(([row, item]) => {
        const biddedAssets = Number(item?.bidded_asset_count) || 0;
        const assetCount = Number(item?.asset_count) || 0;
        const biddersParticipated =
          Number(item?.participating_bidders_count) || 0;
        const subscribedUsers = Number(item?.subscribed_users_count) || 0;

        totalAssetsBidded += biddedAssets;
        totalAssetsCount += assetCount;

        if (!stateWiseBidders[item?.state]) {
          stateWiseBidders[item?.state] = biddersParticipated;
        }

        if (!stateWiseSubscribedUsers[item?.state]) {
          stateWiseSubscribedUsers[item?.state] = subscribedUsers;
        }

        const rowData = [
          serialNumber++,
          item?.auction_id || "-",
          item?.state || "-",
          item?.segment || "-",
          item?.brand_name || "-",
          assetCount || "-",
          `${biddedAssets} / ${assetCount}` || "-",
          item?.average_selling_price || "-",
          `${biddersParticipated} / ${subscribedUsers}` || "-",
        ];
        data.push(rowData);
      });

      const totalBiddersParticipated = Object.values(stateWiseBidders).reduce(
        (acc, count) => acc + count,
        0
      );

      const totalSubscribedUsers = Object.values(
        stateWiseSubscribedUsers
      ).reduce((acc, count) => acc + count, 0);

      const totalRow = [
        "Total",
        "-",
        "-",
        "-",
        "-",
        totalAssetsCount,
        `${totalAssetsBidded} / ${totalAssetsCount}`,
        "-",
        `${totalBiddersParticipated} / ${totalSubscribedUsers}`,
      ];
      data.push(totalRow);

      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Auction overview");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const dataBlob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(dataBlob);
      link.download = "Live Auction Overview.xlsx";
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  try {
    return (
      <>
        <div className="container mt-2">
          <div class="shadow-sm p-3 mb-5 bg-body-tertiary rounded mt-4">
            <div className="d-flex justify-content-between flex-row flex-wrap align-items-center gap-2 mx-2">
              <div className=" h5 pb-1 mb-1">
                <span className="border-bottom">Auction Overview</span>
              </div>

              <div className="d-flex flex-wrap justify-content-between col-md-8">
                <div className="h6 pb-1 mb-1 d-flex justify-content-between flex-row flex-wrap align-items-center">
                  <span className="border-bottom">
                    Download :
                    {Object.entries(filteredData)?.length === 0 ? (
                      <span className="text-danger">
                        <bold>No Data</bold>
                      </span>
                    ) : (
                      <span
                        className="text-primary ms-1"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleDownloadExcel(e, filteredData);
                        }}
                      >
                        Excel
                      </span>
                    )}
                  </span>
                </div>
                <div className=" col-8 col-md-6 col-lg-5 d-flex gap-2 mb-2">
                  <SearchBoxWithCustomQuery
                    allData={allData}
                    setFilteredData={setFilteredData}
                    placeholder="Search..."
                    return_array={true}
                    keysToSearchOn={[
                      "auction_id",
                      "c_auction_id",
                      "bidder_phone",
                      "bidder_name",
                      "brand_name",
                      "mfg_year",
                      "reg_no",
                      "chassis_no",
                      "eng_no",
                      "reference_no",
                      "loan_uid",
                    ]}
                  />
                </div>
                <div className="">
                  <div class="input-group flex-nowrap mt-1 me-auto">
                    <button
                      class=" btn btn-secondary btn-sm  btn-md"
                      id="basic-addon1"
                      onClick={() => {
                        setFetchData((prev) => !prev);
                        fetchData();
                      }}
                      disabled={loading || loadingData}
                    >
                      {loading || loadingData ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            aria-hidden="true"
                            style={{ fontSize: "10px" }}
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        <i className="bi bi-arrow-clockwise"></i>
                      )}
                    </button>
                    <button
                      class="btn btn-outline-secondary btn-sm  btn-md"
                      type="button"
                      disabled
                    >
                      {!loading || loadingData ? timer : "-"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="container table-responsive"
              style={{
                minHeight: "10rem",
                maxHeight: "60vh",
                overflow: "auto",
              }}
            >
              <div ref={tableRef} className="">
                <TableWithProgressBar
                  data={filteredData}
                  columns={table_columns.filter((column) => column)}
                  loading={loading}
                  loading_cols={table_columns.filter((column) => column).length}
                  loading_rows={10}
                  no_data_msg="No Data Found"
                  flag="OverView"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } catch (error) {
    console.log(error);
    return <ErrorHandler error={error} />;
  }
}

export default AuctionOverview;

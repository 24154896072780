export function formatDate(date) {
  try {
    const day = String(date?.getDate())?.padStart(2, "0");
    const month = String(date?.getMonth() + 1)?.padStart(2, "0");
    const year = date?.getFullYear();
    return `${year}-${month}-${day}`;
  } catch (error) {
    return date;
  }
}

export function formatDateDDMMYYYY(date) {
  try {
    const day = String(date?.getDate())?.padStart(2, "0");
    const month = String(date?.getMonth() + 1)?.padStart(2, "0");
    const year = date?.getFullYear();
    return `${day}-${month}-${year}`;
  } catch (error) {
    return date;
  }
}

export function DateConverterForInputField(date) {
  try {
    const [day, month, year] = date.split("-");
    return `${year}-${month}-${day}`;
  } catch (error) {
    return date;
  }
}
export function formatDateWithSuffix(date) {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  const daySuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${daySuffix(day)} ${month} ${year}`;
}

export function getDays(date1, date2) {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

import ErrorHandler from "../ErrorHandler";
import "../../css/common.css";
import { useEffect, useMemo, useState } from "react";

function Table({
  data,
  columns,
  loading,
  loading_rows = 0,
  loading_cols = 0,
  no_data_msg = "No Data Found",
  td_text_position = "center",
  th_text_position = "center",
  ...props
}) {
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    if (loading) {
      setSortOrder({
        column: null,
        order: null,
      });
    }
  }, [loading]);

  const getDataFromDataKey = (row, data_key) => {
    let keys = data_key.split(".");
    let value = row;
    keys.forEach((key) => {
      value = value[key];
    });
    return value;
  };

  const [sortOrder, setSortOrder] = useState({
    column: null,
    order: null,
  });

  const sortColumn = () => {
    let sorted_data = structuredClone(data);
    if (sortOrder.order === "asc") {
      sorted_data = filteredData.toSorted((a, b) => {
        return a[sortOrder.column.data_key].localeCompare(
          b[sortOrder.column.data_key],
          undefined,
          {
            numeric: true,
          }
        );
      });
    }

    if (sortOrder.order === "desc") {
      sorted_data = filteredData.toSorted((a, b) => {
        return b[sortOrder.column.data_key].localeCompare(
          a[sortOrder.column.data_key],
          undefined,
          {
            numeric: true,
          }
        );
      });
    }
    return sorted_data;
  };

  const sortedData = useMemo(() => {
    let sorted_data = filteredData;
    sorted_data = sortColumn();

    if (loading) return sorted_data;
    if (!sortOrder?.column) return sorted_data;
    if (sorted_data?.length === 0) return sorted_data;

    return sorted_data;
  }, [sortOrder, loading, filteredData, data]);

  try {
    return (
      <>
        <table class=" table table-bordered">
          <thead>
            <tr className="mt-border-red-extra-light sticky-top bg-white">
              {columns.map((column, index) => (
                <th
                  key={`thead_${column?.data_key}_${index}`}
                  rowSpan={column?.row_span}
                  colSpan={column?.col_span}
                  className={
                    (column?.reset_default_bootstrap_classes_thead
                      ? ""
                      : "align-middle mt-border-red-extra-light mt-bg-red-bg text-sm ") +
                    (column?.th_class || "") +
                    `text-${
                      column?.th_text_position
                        ? column?.th_text_position
                        : th_text_position
                    }`
                  }
                  style={{
                    ...column?.style,
                    cursor: column?.sortable ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (
                      column?.sortable &&
                      !loading &&
                      filteredData?.length > 0
                    ) {
                      setSortOrder((prev) => {
                        if (prev?.column?.data_key === column?.data_key) {
                          return {
                            column: column,
                            order: prev.order === "asc" ? "desc" : "asc",
                          };
                        } else {
                          return {
                            column: column,
                            order: "asc",
                          };
                        }
                      });
                    }
                  }}
                >
                  <span>{column?.title} </span>{" "}
                  <span>
                    {sortOrder.column?.data_key === column.data_key ? (
                      sortOrder.order === "asc" ? (
                        <i className="bi bi-arrow-up"></i>
                      ) : (
                        <i className="bi bi-arrow-down"></i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading
              ? Array(loading_rows)
                  .fill(0)
                  .map((v, i) => {
                    return (
                      <tr
                        key={`${i}_table_loading_row`}
                        style={{ cursor: "wait" }}
                        className="mt-border-red-extra-light"
                      >
                        {Array(loading_cols)
                          .fill(0)
                          .map((v, j) => {
                            return (
                              <td
                                className="mt-border-red-extra-light"
                                key={`${j}_table_loading_column`}
                              >
                                <p className="placeholder-glow mb-0">
                                  <span className="placeholder col-12"></span>
                                </p>
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })
              : sortedData &&
                sortedData?.map((row, row_index) => {
                  return (
                    <tr
                      key={`data_row_${row_index}`}
                      className="mt-border-red-extra-light"
                    >
                      {columns.map((column, column_index) => (
                        <td
                          className={
                            (column?.reset_default_bootstrap_classes_td
                              ? " "
                              : "align-middle mt-border-red-extra-light text-sm ") +
                            (column?.td_class || "") +
                            `text-${
                              column?.td_text_position
                                ? column?.td_text_position
                                : td_text_position
                            }`
                          }
                          colSpan={column?.td_col_span}
                          rowSpan={column?.td_row_span}
                          style={column?.td_style}
                          key={`data_row_${row_index}_column_${column_index}`}
                        >
                          {column?.render
                            ? column?.render(row, row_index, column_index)
                            : getDataFromDataKey(row, column?.data_key)}
                        </td>
                      ))}
                    </tr>
                  );
                })}
          </tbody>
        </table>
        {!loading && filteredData.length === 0 && (
          <div className=" text-center mt-text-red-regular">{no_data_msg}</div>
        )}
      </>
    );
  } catch (error) {
    console.error(error);
    return <ErrorHandler error={error} />;
  }
}

export default Table;

import axios from "axios";
import { errorToast } from "../../react-toastfiy/toast";

const excludedURLs = ["/refreshToken", "/login-react"];

// const MT_URL = "https://meratractor.com";
// const MT_URL = "http://192.168.1.13:8001";
// const MT_URL = "http://127.0.0.0:8001";
// const MT_URL = "http://0.0.0.0:8001";
// const MT_URL = "http://192.168.1.8:8003";
// const MT_URL = "http://192.168.72.179:8001";
// const MT_URL = "http://172.20.10.14:8001";
// const MT_URL = "http://192.168.1.8:8001";
// const MT_URL = "http://192.168.1.6:8001";
// const MT_URL = "http://192.168.1.14:8001";
// const MT_URL = "http://192.168.1.11:8000";
// const MT_URL = "https://test.meratractor.com";
const MT_URL = "https://api.meratractor.com";
// const MT_URL = "http://localhost:8000";
// const MT_URL = "http://localhost:8001";

// const location = useLocation();
// const asset_id = location.pathname.split(":")[1];

const cookieAxios = axios.create({
  baseURL: MT_URL,
  withCredentials: true,
});

const handle401Response = () => {
  localStorage.clear();
  window.tokenAboutToExpire = false;
  window.fetchingRefreshToken = false;
  window.requestQueue = [];
  window.location.replace("/sign-in");
};

cookieAxios.interceptors.response.use(
  (resp) => {
    if (
      resp?.data?.status === 401 &&
      !excludedURLs
        ?.map?.((val) => `${MT_URL}${val}`)
        ?.includes(resp.config?.url)
    ) {
      errorToast(resp?.data?.msg);
      handle401Response();
      return resp;
    } else {
      return resp;
    }
  },
  (error) => {
    if (
      error.response?.status === 401 &&
      excludedURLs
        ?.map?.((val) => `${MT_URL}${val}`)
        ?.includes(error.config?.url)
    ) {
      errorToast(error.response?.data?.msg);
      handle401Response();
    }
    return Promise.reject(error);
  }
);

class AuctionService {
  MeratractorLogin = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/login-react`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  AuctionLogout = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/logout-app-user`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetUserDetailsById = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/user/get-user-details-by-id`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ListAllUsers = async (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/user/list-all-users`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllApprovalPending = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auction-approval-pending`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAssetApprovalPending = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-asset-approval-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ClosedApprovalRequest = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/update-auction-erp-auction-close-request`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateApprovalStatus = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/update-auction-erp-asset-status`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllPaymentPending = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auctions-payment-pending`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAssetPaymentPending = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-assets-payment-pending`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAllPaymentsDetails = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-utrlogs-data`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllFinancierEmailAuctionWise = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-financier-wise-emails`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllCompletedAuction = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-completed-auctions-from-date-range`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  EditChangeClosedRequest = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/edit-change-close-request`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionChangeClosedRequestApprovalPending = async (
    formData,
    source = {}
  ) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-completed-auctions-change-close-request-st`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ClosedRequestApproval = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/update-change-close-request`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetFinancierWiseAuctionList = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/auction-erp-st-dashboard`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetStateWiseFinancierAuctionList = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/auction-erp-admin-dashboard`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetStateWiseFinancierAuctionListCountData = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/auction-erp-admin-dashboard-count-data`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ApprovedPendingCount = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auction-approval-pending-count`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  PaymentPendingCount = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auctions-payment-pending-count`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  AuctionChangeClosedRequestApprovalPendingCount = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-completed-auctions-change-close-request-st-count`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetBackoutApprovalCount = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "get",
      url: `${MT_URL}/auctions/get-backout-charges-no-data-count`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetState = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-user-state-permissions`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetSegmentType = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "get",
      url: `${MT_URL}/auctions/get-all-segments`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAuctionReport = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-representative-reports-auctions-listing`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionRepresentativeReportsAssetsListing = async (
    formData,
    source = {}
  ) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-representative-reports-assets-listing`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAuctionState = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-all-states-open-api`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetUserList = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },

      method: "post",
      url: `${MT_URL}/wallet/get_phone_name`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateAuctionSummaryAsset = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/update-auction-summary-asset`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAssetForBackoutApprovalPending = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-backout-charges-no-data`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateBackoutChargesStatus = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/update-backout-charges-status`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionsListBondKYCDownload = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/auction-list-bond-kyc-download`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAllAuctionAssetsPaymentPending = async (formData) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auction-assets-payment-pending`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetListBondKYCDownload = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/asset-list-bond-kyc-download`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionWiseReleaseOrBankNOCPending = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/auction-wise-release-or-banknoc-pending`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetWiseReleaseOrBankNOCPending = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/asset-wise-release-or-banknoc-pending`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ReleaseDoneOfAsset = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/release-done-of-asset`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  BankNOCDoneOfAsset = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/banknoc-done-of-asset`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  AuctionWonReports = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/auction-won-report`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ClientCompletedAuctionIDsForERP = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/client/get-completed-auction-ids-for-erp`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetBackoutUserList = async () => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/user/get-all-blocked-users`,
      // data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateBackoutUserStatus = async (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/user/update-blocked-users`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ClientCompletedAuctionDataForERP = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/client/get-completed-auction-data-for-erp`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionERPAuctionsPaymentPendingCount = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-erp-auctions-payment-pending-count`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetBrandList = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-brand-list`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetFinanciersList = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/api/financiers_erp`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  CreatAuction = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/add`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAuction = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/get_auctions`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetARAuction = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get-auction-admin`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  SwitchAuctionData = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/switch/auction`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAssetByAssetId = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/get_auction_details_by_uid`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UpdateAssetByAssetId = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/update_auction_by_id`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UploadSingleAsset = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/upload_single_asset`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UploadMultipleAsset = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/upload_multiple_assets`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  RemoveCardAPI = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "POST",
      url: `${MT_URL}/auctions/delete/card_one_by_one`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionStatus = async (asset_id, formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/asset-status?uid=${asset_id}`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetViewAuctionAssets = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/view/assets_admin`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetViewAuctionAssetsFirebase = async () => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/get/firebase`,
      // data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAuctionSummary = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/auction_summary`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  RefreshNow = async (auction_id, formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/updatelnthighestbid?uid=${auction_id}`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAuctionAcr = async (asset_id, formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/acr?uid=${asset_id}`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  LockBid = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/lock-bid`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UnLockBid = async (formData, source = {}) => {
    return cookieAxios({
      // headers: {
      //   "Content-Type": "application/json",
      // },
      method: "post",
      url: `${MT_URL}/auctions/unlock-bids`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAssetPictures = async (uid, formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/get_asset_pictures_by_id?ref_no=${uid}`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UploadAssetPictures = async (uid, formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/upload_asset_images?uid=${uid}`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  DeleteAssetPictures = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/delete/assetImage`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  SetAssetPicturesPrimary = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/view/asset_images`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetBidderNameAndPhone = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/get_phone_name`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UpdateAuctionSummary = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/update-auction-summary-asset`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UploadImage = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/upload_asset_excel`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetUserStateList = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/user/api/statelist`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetUserLoginDetails = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/user/get/loginDetails`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UserLogout = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/user/logout/users`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetFinancier = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/get-all-financiers`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  CreateFinancier = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/add-financier`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateFinancier = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/update-financier`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DeleteFinancier = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/delete-financier-by-id`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetSegments = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/get-all-segments-page-wise`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  CreateSegment = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/add-segment`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UpdateSegment = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/update-segment`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DeleteSegment = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/delete-segment-by-id`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetStateByPageNo = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/get-all-states-pg-no`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  CreateState = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/add-state`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateState = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/update-state`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DeleteState = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/delete-state-by-id`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetBrand = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/get-all-brands`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };  
  GetBrandState = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/get-all-brandstates`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  }; 
  GetAllBrand = async (formData, source = {}) => {
    return cookieAxios({
      method: "get",
      url: `${MT_URL}/auctions/get-all-brands-list`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  CreateBrand = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/add-brand`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };  
  CreateBrandState = async (formData, source = {}) => {
    for (let pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/add-brandstate`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  UpdateBrand = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/update-brand`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  }; 
  UpdateBrandState = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/update-brandstate`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DeleteBrand = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/delete-brand-by-id`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };  
  DeleteBrandState = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/delete-brandstate-by-brand-and-state-id`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  CreateSingleTransaction = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/wallet/single-transaction`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetTransactionByUser = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/view-transactions-by-user`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetMultiTransactionForOneUser = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/upload-transactions-for-one-user`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAllTransaction = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/get-all-transactions`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAllTransactionbyDate = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/get-all-transactions-by-date`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UploadMultipleTransactionExcelFile = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/upload-multiple-transactions`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetLedgerBalanceDetails = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/ledger-balance-details`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetUserListData = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/user/api/userlist`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetStateListData = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/user/api/statelist`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  Permissions = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/get/state/permission`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UpdatePermissions = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/state/permission`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  WalletNotLinked = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/wallet-not-linked-userlist`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  WalletLinked = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/link-wallet`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  AddUserToWallet = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/user/add-user-to-wallet`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  ExportExcelUserDetails = async (formData, source = {}) => {
    return cookieAxios({
      method: "get",
      url: `${MT_URL}/wallet/export-excel-user-details`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetImage = async (newAsset, formData, source = {}) => {
    return cookieAxios({
      method: "GET",
      url: `${MT_URL}/auctions/get_asset_pictures_by_id?ref_no=${newAsset}`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  EditAssetImagePrimary = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/view/asset_images`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  EditAssetImageDelete = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/auctions/delete/assetImage`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetSummary = async (newAsset, formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/asset_summary?ref_no=${newAsset}`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  DeleteBid = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/delete/bid`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  DeleteAsset = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/delete/asset`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAssetDetails = async (url, formData, source = {}) => {
    return cookieAxios({
      method: "get",
      url: `${MT_URL}/auctions/view/assetbyid?ref_no=${url}`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UpdateAssetDetails = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/edit/asset`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAutoBid = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/view/view_auction_autobid`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DeleteAutoBid = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/delete_user_autobid`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  UploadMultipleTransactionForSingleUserHandleExcelofUserDetails = async (
    formData,
    source = {}
  ) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/excel-user-details-data`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DownloadAllBalanceList = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/ledger-balance-details-download-data`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionCompletionDetails = async (formData, source = {}) => {
    return axios({
      method: "post",
      url: `/operations/get-auction-completion-state-financier-wise`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionInDateRangeList = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/dashboard/get-auction-in-date-range`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetAuctionUserList = async (formData, source = {}) => {
    return cookieAxios({
      method: "POST",
      url: `${MT_URL}/dashboard/auction-user-data`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAssetWiseBiddingData = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/dashboard/asset-wise-bidding-data`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetMMFSLLotsCount = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/dashboard/get-mmfsl-lots-count`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetMMFSLLotsCountModalData = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/dashboard/get-lot-wise-assets`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  DownloadACR = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/download-acr`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetMMFSLLotsImage = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/download-mmfsl-asset-images`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  SendECR = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/send-mmfsl-manual-acr-function`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GetMMFSLLotsCountModal = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/dashboard/get-lot-wise-unique-states`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  RetryACRGeneration = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/regenerate-auction-acr`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  RetryACRBatchWiseApi = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/retry-mmfsl-acr`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  checkMMFSLACRStatusAPI = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/get-mmfsl-acr-status`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetUserAnalysisData = async (formData, source = {}) => {
    return cookieAxios({
      method: "get",
      url: `${MT_URL}/dashboard/get-this-month-active-dormant-churn-users`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  DownloadViewAllTransaction = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/wallet/get-all-transactions-download`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  searchFinancier = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/search-financier-by-name`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  searchSegment = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/search-segment-by-name`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  SearchState = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/search-state-by-name`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  SearchBrand = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/search-brand-by-name`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  SearchPermission = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/user/api/userlist_search`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  CheckBidAmount = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/check-bid-amount`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  GetAuctionAssetAverageBidForERP = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `/auctions/get-auction-asset-avg-bid-for-erp`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetNoBiddedAssetsData = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `/auctions/get-no-bidded-assets-data`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  UploadMultiAssetStartPrice = async (formData, source = {}) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/update-multiple-assets-start-price`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetAssetPicturesById = (ref_no) => {
    return cookieAxios({
      method: "get",
      url: `/auctions/get_asset_pictures_by_id?ref_no=${ref_no}`,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetUserApprovalAnalysisData = (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/get-users-bids-and-approval-data`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetUserStatePermission = (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/get-user-state-permissions`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetLiveAuction = (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/get-auctions-for-dashboard`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetLiveAuctionAssetsData = (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/get-auction-assets-data`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetAuctionBiddersData = (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/get-auction-bidders-data`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetApprovalSummaryOfUser = (formData) => {
    return cookieAxios({
      method: "post",
      url: `${MT_URL}/auctions/approval-summary-of-user`,
      data: formData,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
}
export default new AuctionService();

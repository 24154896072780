import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthContextProvider } from "./context/auth-context.jsx";
import { UserAuthContextProvider } from "./context/JwtAuthContext.jsx";

import "./css/full-page-spinner.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./interceptors/axios";
import "./index.css";
import "./css/common.css";

window.fetchingRefreshToken = false;
window.requestQueue = [];
window.tokenAboutToExpire = false;

window.String.prototype.toCapitalize = function () {
  return this.split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // Provide the client to your App
  <AuthContextProvider>
    <UserAuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </UserAuthContextProvider>
  </AuthContextProvider>
);

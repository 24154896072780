import { useEffect, useState } from "react";
// import { errorToast } from "../react-toastfiy/toast";
import { errorToast } from "../react-toastfiy/toast";

function SearchBoxWithCustomQuery(props) {
  const {
    allData,
    setFilteredData,
    placeholder = "Search...",
    return_array = false,
    className = "",
    keysToSearchOn = [],
  } = props;
  const [searchQuery, setSearchQuery] = useState("");

  // Debounce function
  function debounce(func, delay) {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  const handleSearchQueryChanged = (val, data) => {
    try {
      data = structuredClone(data);
      let searchInput = val.toLowerCase();

      if (searchInput.length === 0) {
        setFilteredData(allData);
        return;
      }

      const searchQueries = searchInput
        .split(" ")
        .filter((item) => item !== "");

      let results = Object.entries(data).filter(([key, entry]) => {
        return searchQueries.some((item) => {
          item = item?.toString().toLowerCase();
          return keysToSearchOn?.some((key_to_search) => {
            return (
              entry[key_to_search]
                ?.toString()
                ?.toLowerCase()
                ?.startsWith(item) ||
              entry[key_to_search]?.toString()?.toLowerCase()?.endsWith(item) ||
              entry[key_to_search]?.toString()?.toLowerCase()?.includes(item)
            );
          });
        });
      });
      results = Object.fromEntries(results);

      console.log("results", results);
      if (return_array) {
        results = Object.values(results);
      }
      setFilteredData(results);
    } catch (error) {
      console.error(error);
      errorToast(error?.message);
    }
  };

  // const newHandleSearchQueryChanged = (val, data) => {
  //   try {
  //     data = structuredClone(data);
  //     let searchInput = val.toLowerCase();
  //     if (searchInput.length === 0) {
  //       setFilteredData(allData);
  //       return;
  //     }

  //     const searchQueries = searchInput.split(" ").filter((item) => item !== "");

  //     // Search in the name key first
  //     let nameResults = Object.entries(data).filter(([key, entry]) => {
  //       return searchQueries.every((item) => {
  //         item = item?.toString().toLowerCase();
  //         return entry?.name?.toString()?.toLowerCase()?.startsWith(item);
  //       });
  //     });

  //     // Search in other keys excluding the name key
  //     let otherResults = Object.entries(data).filter(([key, entry]) => {
  //       return !nameResults.some(([nameKey, nameEntry]) => nameKey === key) &&
  //         searchQueries.some((item) => {
  //           item = item?.toString().toLowerCase();
  //           return (
  //             key?.toString()?.toLowerCase()?.startsWith(item) ||
  //             (entry && Object.keys(entry).some(subKey => {
  //               if (subKey === 'name' || subKey === 'reporting_personnel') return false; // Skip the name and reporting_personnel keys
  //               if (typeof entry[subKey] === 'string') {
  //                 return entry[subKey].toLowerCase().startsWith(item);
  //               } else if (entry[subKey] && typeof entry[subKey] === 'object' && !Array.isArray(entry[subKey])) {
  //                 return Object.keys(entry[subKey]).some(innerKey => {
  //                   return typeof entry[subKey][innerKey] === 'string' && entry[subKey][innerKey].toLowerCase().startsWith(item);
  //                 });
  //               }
  //               return false;
  //             }))
  //           );
  //         });
  //     });

  //     let results = [...nameResults, ...otherResults];
  //     results = Object.fromEntries(results);
  //     if (return_array) {
  //       results = Object.values(results);
  //     }
  //     setFilteredData(results);
  //   } catch (error) {
  //     console.error(error);
  //     errorToast(error?.message);
  //   }
  // };

  // const debouncedSearch = debounce(newHandleSearchQueryChanged, 300);

  const debouncedSearch = debounce(handleSearchQueryChanged, 300);

  useEffect(() => {
    handleSearchQueryChanged(searchQuery, allData);
    // newhandleSearchQueryChanged(searchQuery, allData);
    // debouncedSearch(searchQuery, allData);
  }, [searchQuery]);

  return (
    <input
      type="search"
      name="search-form"
      id="search-form"
      className={`form-control ${className}`}
      placeholder={placeholder}
      onChange={(e) => {
        setSearchQuery(e.target.value);
      }}
      value={searchQuery}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    />
  );
}

export default SearchBoxWithCustomQuery;

import jwtDecode from "jwt-decode";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SearchBoxWithCustomQuery from "../../common/SearchBoxWithCustomQuery";
import TableWithProgressBar from "../../common/Table/TableWithProgressBar";
import { errorToast } from "../../react-toastfiy/toast";
import auctionService from "../../services/auction/auction.service";
import "./../../css/auctionDashboard.css";
import useAutoFetch from "../../hooks/useAutoFetch";
import * as XLSX from "xlsx";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";

const useDetectFirstRender = () => {
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);
  return firstRender;
};

const AuctionAssetsData = () => {
  const currentUser = localStorage.getItem("refreshToken");
  const user = jwtDecode(currentUser);
  const history = useHistory();
  const location = useLocation();
  const auctionData = location.state?.filteredData;
  const flag = location.state?.flag;

  const tableRef = useRef(null);

  const firstRenders = useDetectFirstRender();

  const [refno, setRefno] = useState([]);
  const [bidderName, setBidderName] = useState([]);
  const [allAssetData, setAllAssetData] = useState([]);
  const [filteredAssetData, setFilteredAssetData] = useState([]);
  const [filteredBiddersData, setFilteredBiddersData] = useState([]);

  const [allBiddersData, setAllBiddersData] = useState([]);
  const [allBiddersDataByAssetId, setAllBiddersDataByAssetId] = useState([]);
  const [allAssetsDataByBiddersPhone, setAllAssetsDataByBiddersPhone] =
    useState([]);
  const [
    filteredAssetsDataByBiddersPhone,
    setFilteredAssetsDataByBiddersPhone,
  ] = useState([]);
  const [filteredBiddersDataByAssetId, setFilteredBiddersDataByAssetId] =
    useState([]);
  const [filteredData, setFilteredData] = useState([auctionData]);
  const [loadingData, setLoadingData] = useState(false);
  const [fetchDataF, setFetchData] = useState(true);
  const [loadingBidders, setLoadingBidders] = useState(false);
  const [loadingBidderData, setLoadingBidderData] = useState(false);
  const [loadingAssetsData, setLoadingAssetsData] = useState(false);
  const [showBidderTable, setShowBidderTable] = useState(false);
  const [showAssetsTable, setShowAssetsTable] = useState(false);

  const isFirstFetch = useRef(true);

  const fetchData = useCallback(async () => {
    try {
      if (!isFirstFetch.current) {
        setLoadingData(true);
        setLoadingBidders(true);
      }
      const form_data = new FormData();
      form_data.append("auction_id", auctionData?.auction_id);

      if (flag === "auction_id") {
        form_data.append("bidded", "all");
      } else if (flag === "bidded_asset_count") {
        form_data.append("bidded", true);
      } else if (flag === "asset_count") {
        form_data.append("bidded", false);
      }
      if (
        flag !== "participating_bidders_count" &&
        flag !== "nonparticipating_bidders_count"
      ) {
        const res = await auctionService.GetLiveAuctionAssetsData(form_data);
        if (res?.status === 200) {
          setTimer("30");
          setFilteredAssetData(res?.data);
          setAllAssetData(res?.data);
        } else {
          errorToast(res?.msg);
        }
      }
      const form_data_bidders = new FormData();
      form_data_bidders.append("auction_id", auctionData?.auction_id);
      if (flag !== "nonparticipating_bidders_count") {
        form_data_bidders.append("bidded", "all");
      } else {
        form_data_bidders.append("not_bidded", true);
      }

      const resBidders = await auctionService.GetAuctionBiddersData(
        form_data_bidders
      );

      if (resBidders?.status === 200) {
        setTimer("30");
        setFilteredBiddersData(resBidders?.data);
        setAllBiddersData(resBidders?.data);
      } else {
        errorToast(resBidders?.msg);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingData(false);
      setLoadingBidders(false);

      isFirstFetch.current = false;
    }
  }, []);

  const { timer, loading, setTimer } = useAutoFetch(fetchData, 30000);

  const table_columns = [
    {
      title: "#",
      data_key: "",
      render: (row, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Ref No",
      data_key: "reference_no",
      sortable: true,
    },
    {
      title: "Repeat #",
      data_key: "asset_occurrence_count",
      sortable: true,
    },
    {
      title: "Mfg. Year",
      data_key: "mfg_yr",
      sortable: false,
    },
    {
      title: "Yard",
      data_key: "yard_name",
      sortable: false,
    },
    {
      title: "Highest Bid",
      data_key: "",
      sortable: false,
      render: (row) => {
        return (
          <>
            <span className=" text-nowrap  text-decoration-none">
              {indianCurrencyFormater(row?.latest_bid)}
            </span>
          </>
        );
      },
    },

    {
      title: "Make / Model",
      data_key: "make_model",
      sortable: false,
    },
    {
      title: "Bidders Count",
      data_key: "",
      sortable: false,
      render: (row) => {
        return (
          <>
            {row?.participating_bidders_count !== 0 ? (
              <span
                style={{ cursor: "pointer" }}
                className=" text-nowrap link-primary text-decoration-none"
                onClick={() => handleShownBidderData(row)}
              >
                {row?.participating_bidders_count}
              </span>
            ) : (
              <span className=" text-nowrap  text-decoration-none">
                {row?.participating_bidders_count}
              </span>
            )}
          </>
        );
      },
    },
  ];
  const table_columns_bidders = [
    {
      title: "#",
      data_key: "",
      render: (row, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Bidders Name ",
      data_key: "",
      sortable: true,
      render: (row) => {
        return (
          <>
            {row?.is_channel_financed !== false ? (
              <span className=" text-nowrap text-decoration-none">
                {`${row?.bidder_name} (TA)`}
              </span>
            ) : (
              <span className=" text-nowrap  text-decoration-none">
                {row?.bidder_name}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Bidders Phone",
      data_key: "bidder_phone",
      sortable: true,
    },
    {
      title: "No of Assets Bidded",
      data_key: "bidded_assets_count",
      sortable: false,
      render: (row) => {
        return (
          <>
            {row?.bidded_assets_count !== 0 ? (
              <span
                style={{ cursor: "pointer" }}
                className=" text-nowrap link-primary text-decoration-none"
                onClick={() => handleShownAssetsData(row)}
              >
                {row?.bidded_assets_count}
              </span>
            ) : (
              <span className=" text-nowrap  text-decoration-none">
                {row?.bidded_assets_count}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Total Winning Value",
      data_key: "",
      sortable: false,
      render: (row, index) => {
        return <>{indianCurrencyFormater(row?.total_winning_bid_value)}</>;
      },
    },
    {
      title: "Loosing/Winning",
      data_key: "total_winning_bid_count",
      sortable: false,
      render: (row) => {
        const losingBidCount =
          row?.bidded_assets_count - row?.total_winning_bid_count;

        return (
          <>
            {row?.total_winning_bid_count !== 0 ? (
              <span
                // style={{ cursor: "pointer" }}
                className="text-nowrap text-decoration-none"
              >
                <span className="text-nowrap text-decoration-none">
                  {losingBidCount} / {row?.total_winning_bid_count}
                </span>
              </span>
            ) : (
              <span className="text-nowrap text-decoration-none">
                {losingBidCount} / {row?.total_winning_bid_count}
              </span>
            )}
          </>
        );
      },
    },
  ];
  const table_columns_bidders_per_assets = [
    {
      title: "#",
      data_key: "",
      render: (row, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Bidders Name",
      data_key: "bidder_name",
      sortable: false,
    },
    {
      title: "Bidders Phone",
      data_key: "bidder_phone",
      sortable: false,
    },
    {
      title: "Total Winning Value",
      data_key: "",
      sortable: false,
      render: (row, index) => {
        return <>{indianCurrencyFormater(row?.total_winning_bid_value)}</>;
      },
    },
    {
      title: "Winning",
      data_key: "total_winning_bid_count",
      sortable: false,
    },
  ];
  const table_columns_nonparticipating_bidders_count = [
    {
      title: "#",
      data_key: "",
      render: (row, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "Bidders Name",
      data_key: "bidder_name",
      sortable: false,
    },
    {
      title: "Bidders Phone",
      data_key: "bidder_phone",
      sortable: false,
    },
    {
      title: "Trade Advance",
      data_key: "",
      sortable: false,
      render: (row) => {
        return (
          <>
            {row?.ta !== false ? (
              <span className=" text-nowrap text-decoration-none">Yes</span>
            ) : (
              <span className=" text-nowrap  text-decoration-none">No</span>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFilteredData((prevData) =>
        prevData.map((item) => {
          const remainingTime = getRemainingTime(item?.auction_end_time);
          return { ...item, remainingTime };
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getRemainingTime = (endTime) => {
    if (!endTime) return "Invalid Date";

    const now = moment();
    const end = moment(endTime, "DD-MM-YYYY HH:mm:ss");
    if (!end.isValid()) return "Invalid Date";

    const duration = moment.duration(end.diff(now));

    if (duration.asSeconds() <= 0) return "Expired";

    return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
  };

  const handleShownBidderData = (data) => {
    try {
      setLoadingBidderData(true);
      setShowAssetsTable(false);
      setShowBidderTable(true);
      setRefno(data?.reference_no);
      const form_data = new FormData();
      form_data.append("auction_id", data?.c_auction_id);
      form_data.append("reference_no", data?.reference_no);
      form_data.append("bidded ", "all");
      auctionService.GetAuctionBiddersData(form_data).then((res) => {
        setLoadingBidderData(false);

        if (res?.status === 200) {
          setFilteredBiddersDataByAssetId(res?.data);
          setAllBiddersDataByAssetId(res?.data);
        } else {
          errorToast(res?.msg);
        }
      });
    } catch (error) {
      errorToast(error?.msg);
    }
  };
  const handleShownAssetsData = (data) => {
    try {
      setLoadingAssetsData(true);
      setBidderName(data?.bidder_name);
      setShowBidderTable(false);
      setShowAssetsTable(true);
      const form_data = new FormData();
      form_data.append("auction_id", auctionData?.auction_id);
      form_data.append("bidded  ", true);
      form_data.append("participated ", true);
      form_data.append("bidder_phone  ", data?.bidder_phone);
      auctionService.GetLiveAuctionAssetsData(form_data).then((res) => {
        setLoadingAssetsData(false);

        if (res?.status === 200) {
          setFilteredAssetsDataByBiddersPhone(res?.data);
          setAllAssetsDataByBiddersPhone(res?.data);
        } else {
          errorToast(res?.msg);
        }
      });
    } catch (error) {
      errorToast(error?.msg);
    }
  };

  const handleDownloadExcel = (e, value) => {
    e.preventDefault();

    if (Object.entries(value)?.length > 0) {
      const data = [];
      const columns = [
        "Sr. No.",
        "Auction Id",
        "Ref No",
        "Mfg. Year",
        "Yard",
        "Highest Bid	",
        "Make / Model",
        "Bidders Count",
      ];
      data.push(columns);

      let serialNumber = 1;
      Object.entries(value).forEach(([row, item]) => {
        const rowData = [
          serialNumber++,
          item?.c_auction_id || "-",
          item?.reference_no || "-",
          item?.mfg_yr || "-",
          item?.yard_name || "-",
          item?.latest_bid || "-",
          item?.make_model || "-",
          item?.participating_bidders_count || "-",
        ];
        data.push(rowData);
      });

      const totalBiddersParticipated = Object.entries(value).reduce(
        (acc, [_, item]) =>
          acc + (Number(item?.participating_bidders_count) || 0),
        0
      );

      const totalRow = [
        "Total",
        "-",
        "-",
        "-",
        "-",
        "-",
        "-",
        totalBiddersParticipated,
      ];
      data.push(totalRow);

      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Auction overview");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const dataBlob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(dataBlob);
      link.download = `Assets wise Bidders Data${auctionData?.auction_id}.xlsx`;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };
  const handleDownloadBiddersDataExcel = (e, value) => {
    e.preventDefault();

    if (Object.entries(value)?.length > 0) {
      const data = [];
      const columns = [
        "Sr. No.",
        "Bidders Name",
        "Bidders Phone",
        "No of Assets Bidded",
        "Total Winning Value",
        "Winning Assets",
      ];
      data.push(columns);

      let serialNumber = 1;

      Object.entries(value).forEach(([row, item]) => {
        const rowData = [
          serialNumber++,
          item?.bidder_name || "-",
          item?.bidder_phone || "-",
          item?.bidded_assets_count || "-",
          item?.total_winning_bid_value || "-",
          item?.total_winning_bid_count || "-",
        ];
        data.push(rowData);
      });

      const totalBiddersParticipated = Object.entries(value).reduce(
        (acc, [_, item]) => acc + (Number(item?.total_winning_bid_count) || 0),
        0
      );

      const totalRow = ["Total", "-", "-", "-", "-", totalBiddersParticipated];
      data.push(totalRow);

      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Auction overview");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const dataBlob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(dataBlob);
      link.download = `Participating Bidders Data For${auctionData?.auction_id}.xlsx`;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };
  const handleDownloadNonParticipatedBiddersDataExcel = (e, value) => {
    e.preventDefault();

    if (Object.entries(value)?.length > 0) {
      const data = [];
      const columns = [
        "Sr. No.",
        "Bidders Name",
        "Bidders Phone",
        "Trade Advance",
      ];
      data.push(columns);

      let serialNumber = 1;

      Object.entries(value).forEach(([row, item]) => {
        const rowData = [
          serialNumber++,
          item?.bidder_name || "-",
          item?.bidder_phone || "-",
          item?.is_channel_financed === true ? "Yes" : "No",
        ];
        data.push(rowData);
      });

      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Auction overview");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const dataBlob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(dataBlob);
      link.download = `Non Participated Bidders For${auctionData?.auction_id}.xlsx`;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  const handleChangeURL = () => {
    if (user.role === "Super Admin" || user.role === "Developer") {
      history.push(`/superAdminDashboard:${user?.phone}&state=${user?.state}`);
    } else if (user.role === "Auction Back Office") {
      history.push(
        `/auction_back_office/auction_dashboard:${user?.phone}&state=${user?.state}`
      );
    } else if (user.role === "Auction Representative") {
      history.push(
        `/auction_representative/auction_dashboard:${user?.phone}&state=${user?.state}`
      );
    } else if (user.role === "Auction Super Admin") {
      history.push(
        `/asaDashboard/auction_dashboard:${user?.phone}&state=${user?.state}`
      );
    }
  };

  return (
    <>
      <div className="container-fluid shadow-sm">
        <div className="container">
          <div className="row">
            <div className="d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <h1 className="fs-5 fs-md-3 fs-lg-2">{user.name}</h1>
                </div>
                <div className="col-12 col-sm-6">
                  <h1 className="fs-5 fs-md-4 fs-lg-3">{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block d-lg-none d-xl-none">
              <div className="row">
                <div className="col-12 col-sm-6 mt-2 ">
                  <h1 className="fs-5 fs-md-3 fs-lg-2">{user.name}</h1>
                </div>
                <div className="col-12 col-sm-6 mt-2 mb-2">
                  <h1 className="fs-5 fs-md-4 fs-lg-3">{user.role}</h1>
                </div>
              </div>
            </div>

            <div className="d-none d-md-none d-lg-block d-xl-block">
              <div className="row">
                <div className="col-12 col-md-8 mt-2 ">
                  <h1 className="fs-5 fs-md-3 fs-lg-2">{user.name}</h1>
                </div>
                <div className="col-12 col-md-4 mt-2 mb-2">
                  <h1 className="fs-5 fs-md-4 fs-lg-3">{user.role}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-2">
        <div class="shadow-sm bg-body-tertiary rounded mt-4 ">
          <div className="d-flex justify-content-start flex-row">
            <div className="mt-1">
              <i
                className="bi bi-arrow-left-circle-fill"
                onClick={() => handleChangeURL()}
                style={{
                  position: "relative",
                  color: "blue",
                  borderRadius: "50%",
                  cursor: "pointer",
                  fontSize: "24px",
                }}
              ></i>
            </div>

            <div className="d-flex flex-grow-1 align-items-center justify-content-between flex-wrap ">
              <div className=" financierFont">
                <div className="fw-bold">{auctionData?.brand_name}</div>

                <div className="d-flex flex-wrap">
                  <span className="d-flex align-items-center gap-1 me-1">
                    <i className="bi bi-tag" style={{ color: "blue" }}></i>{" "}
                    {auctionData?.segment}
                  </span>
                  <span className="d-flex align-items-center gap-1 me-1">
                    <i className="bi bi-geo-alt" style={{ color: "blue" }}></i>{" "}
                    {auctionData?.state}
                  </span>
                  <span
                    className="d-flex align-items-center gap-1"
                    style={{ color: "blue" }}
                  >
                    <i className="bi bi-clock"></i>{" "}
                    {filteredData[0]?.remainingTime}
                  </span>
                </div>
              </div>
            </div>

            <div className="">
              <div class="input-group flex-nowrap mt-2 me-auto">
                <button
                  class=" btn btn-secondary btn-sm"
                  id="basic-addon1"
                  onClick={() => {
                    setFetchData((prev) => !prev);
                    fetchData();
                  }}
                  disabled={loading || loadingData}
                >
                  {loading || loadingData ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        aria-hidden="true"
                        style={{ fontSize: "10px" }}
                      ></span>
                      <span className="visually-hidden">Loading...</span>
                    </>
                  ) : (
                    <i className="bi bi-arrow-clockwise"></i>
                  )}
                </button>
                <button
                  class="btn btn-outline-secondary btn-sm"
                  type="button"
                  disabled
                >
                  {!loading || loadingData ? timer : "-"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {flag !== "participating_bidders_count" &&
        flag !== "nonparticipating_bidders_count" && (
          <div className="container mt-2">
            <div class="shadow-sm p-3 mb-5 bg-body-tertiary rounded mt-4">
              <div className="d-flex justify-content-between flex-row flex-wrap align-items-center gap-2 mx-2">
                <div className=" h5 pb-1 mb-1">
                  <span
                    className="border-bottom"
                    onClick={() =>
                      showAssetsTable === true &&
                      (setShowBidderTable(false), setShowAssetsTable(false))
                    }
                    style={{ cursor: "pointer" }}
                  >
                    Assets
                    {!showAssetsTable && <i class="bi bi-chevron-up ms-2"></i>}
                    {showAssetsTable && <i class="bi bi-chevron-down ms-2"></i>}
                  </span>
                </div>
                <div className="d-flex flex-wrap justify-content-between col-md-7 col-lg-5">
                  <div className="h6 pb-1 mb-1 d-flex justify-content-between flex-row flex-wrap align-items-center">
                    <span className="border-bottom">
                      Download :
                      {Object.entries(filteredAssetData)?.length === 0 ? (
                        <span className="text-danger">
                          <bold>No Data</bold>
                        </span>
                      ) : (
                        <span
                          className="text-primary ms-1"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleDownloadExcel(e, filteredAssetData);
                          }}
                        >
                          Excel
                        </span>
                      )}
                    </span>
                  </div>
                  <div className=" col-12 col-md-7 col-lg-7 col-xl-8 d-flex gap-2 mb-2">
                    <SearchBoxWithCustomQuery
                      allData={allAssetData}
                      setFilteredData={setFilteredAssetData}
                      placeholder="Search..."
                      return_array={true}
                      keysToSearchOn={[
                        "latest_bid",
                        "c_auction_id",
                        "reference_no",
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div
                className="container table-responsive"
                style={{
                  maxHeight: showBidderTable
                    ? "7rem"
                    : "25rem" && showAssetsTable
                    ? "0rem"
                    : "25rem",
                  transition: "max-height 0.5s ease-in-out",
                  // overflow: "hidden",
                }}
              >
                <TableWithProgressBar
                  data={filteredAssetData}
                  columns={table_columns.filter((column) => column)}
                  loading={loading}
                  loading_cols={table_columns.filter((column) => column).length}
                  loading_rows={10}
                  no_data_msg="No Data Found"
                  collapse="true"
                  flag="assets"
                />
              </div>

              {showBidderTable && (
                <>
                  <div className="">
                    <div className="d-flex justify-content-between flex-row flex-wrap align-items-center gap-2 mx-2 mt-3">
                      <div className=" d-flex justify-content-between flex-row flex-wrap col-12">
                        <div className=" h5 pb-2 mb-1 col-10">
                          <span className="border-bottom">
                            {` Live Bidders Data for ${refno}`}
                          </span>{" "}
                        </div>{" "}
                        <div className=" d-flex flex-wrap justify-content-end ms-auto">
                          <button
                            type="button"
                            class="btn-close"
                            aria-label="Close"
                            onClick={() => setShowBidderTable(false)}
                          ></button>
                        </div>
                      </div>
                      <div className=" col-12 col-md-6 col-lg-4 d-flex gap-2 mb-2 ms-auto">
                        <SearchBoxWithCustomQuery
                          allData={allBiddersDataByAssetId}
                          setFilteredData={setFilteredBiddersDataByAssetId}
                          placeholder="Search..."
                          return_array={true}
                          keysToSearchOn={[
                            "auction_id",
                            "c_auction_id",
                            "bidder_phone",
                            "bidder_name",
                            "financier_name",
                            "mfg_year",
                            "reg_no",
                            "chassis_no",
                            "eng_no",
                            "reference_no",
                            "loan_uid",
                          ]}
                        />
                      </div>
                    </div>

                    <div
                      className="table-responsive"
                      style={{ minHeight: "7rem", maxHeight: "20rem" }}
                    >
                      <TableWithProgressBar
                        data={filteredBiddersDataByAssetId}
                        columns={table_columns_bidders_per_assets.filter(
                          (column) => column
                        )}
                        loading={loadingBidderData}
                        loading_cols={
                          table_columns_bidders_per_assets.filter(
                            (column) => column
                          ).length
                        }
                        loading_rows={10}
                        no_data_msg="No Data Found"
                        collapse="true"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      {flag !== "bidded_asset_count" &&
        flag !== "asset_count" &&
        flag !== "nonparticipating_bidders_count" && (
          <div className="container mt-4">
            <div class="shadow-sm p-3 mb-5 bg-body-tertiary rounded mt-4">
              <div className="d-flex justify-content-between flex-row flex-wrap align-items-center gap-2 mx-2">
                <div className=" h5 pb-1 mb-1">
                  <span className="border-bottom">Participating Bidders</span>
                </div>

                <div className="d-flex flex-wrap justify-content-between col-md-7 col-lg-5">
                  <div className="h6 pb-1 mb-1 d-flex justify-content-between flex-row flex-wrap align-items-center">
                    <span className="border-bottom">
                      Download :
                      {Object.entries(filteredBiddersData)?.length === 0 ? (
                        <span className="text-danger">
                          <bold>No Data</bold>
                        </span>
                      ) : (
                        <span
                          className="text-primary ms-1"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleDownloadBiddersDataExcel(
                              e,
                              filteredBiddersData
                            );
                          }}
                        >
                          Excel
                        </span>
                      )}
                    </span>
                  </div>
                  <div className=" col-12 col-md-7 col-lg-7 d-flex gap-2 mb-2">
                    <SearchBoxWithCustomQuery
                      allData={allBiddersData}
                      setFilteredData={setFilteredBiddersData}
                      placeholder="Search..."
                      return_array={true}
                      keysToSearchOn={["bidder_name", "bidder_phone"]}
                    />
                  </div>
                </div>
              </div>
              <div
                className="container table-responsive"
                style={{
                  maxHeight: showAssetsTable ? "7rem" : "25rem",
                  transition: "max-height 0.5s ease-in-out",
                  // overflow: "hidden",
                }}
              >
                <TableWithProgressBar
                  data={filteredBiddersData}
                  columns={table_columns_bidders.filter((column) => column)}
                  loading={loading}
                  loading_cols={
                    table_columns_bidders.filter((column) => column).length
                  }
                  loading_rows={10}
                  no_data_msg="No Data Found"
                  collapse="true"
                  flag="bidders"
                />
              </div>
              {showAssetsTable && (
                <>
                  <div className="container mt-4">
                    <div className="d-flex justify-content-between flex-row flex-wrap align-items-center gap-2">
                      <div className=" d-flex justify-content-between flex-row flex-wrap col-12">
                        <div className=" h5 pb-1 mb-1 col-10">
                          <span className="border-bottom">
                            {` Participated Assets by ${bidderName}`}
                          </span>{" "}
                        </div>{" "}
                        <div className=" d-flex flex-wrap justify-content-end ms-auto">
                          <button
                            type="button"
                            class="btn-close"
                            aria-label="Close"
                            onClick={() => setShowAssetsTable(false)}
                          ></button>
                        </div>
                      </div>
                      <div className=" col-12 col-md-6 col-lg-4 d-flex gap-2 mb-2 ms-auto">
                        <SearchBoxWithCustomQuery
                          allData={allAssetsDataByBiddersPhone}
                          setFilteredData={setFilteredAssetsDataByBiddersPhone}
                          placeholder="Search..."
                          return_array={true}
                          keysToSearchOn={[
                            "auction_id",
                            "c_auction_id",
                            "bidder_phone",
                            "bidder_name",
                            "financier_name",
                            "mfg_year",
                            "reg_no",
                            "chassis_no",
                            "eng_no",
                            "reference_no",
                            "loan_uid",
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="container table-responsive"
                    style={{ minHeight: "7rem", maxHeight: "20rem" }}
                  >
                    <TableWithProgressBar
                      data={filteredAssetsDataByBiddersPhone}
                      columns={table_columns.filter((column) => column)}
                      loading={loadingAssetsData}
                      loading_cols={
                        table_columns.filter((column) => column).length
                      }
                      loading_rows={10}
                      no_data_msg="No Data Found"
                      collapse="true"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}

      {flag !== "bidded_asset_count" &&
        flag !== "asset_count" &&
        flag !== "participating_bidders_count" &&
        flag !== "auction_id" && (
          <div className="container mt-4">
            <div class="shadow-sm p-3 mb-5 bg-body-tertiary rounded mt-4">
              <div className="d-flex justify-content-between flex-row flex-wrap align-items-center gap-2 mx-2">
                <div className=" h5 pb-1 mb-1">
                  <span className="border-bottom">
                    Not Participating Bidders
                  </span>
                </div>

                <div className="d-flex flex-wrap justify-content-between col-md-7 col-lg-5">
                  <div className="h6 pb-1 mb-1 d-flex justify-content-between flex-row flex-wrap align-items-center">
                    <span className="border-bottom">
                      Download :
                      {Object.entries(filteredBiddersData)?.length === 0 ? (
                        <span className="text-danger">
                          <bold>No Data</bold>
                        </span>
                      ) : (
                        <span
                          className="text-primary ms-1"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            handleDownloadNonParticipatedBiddersDataExcel(
                              e,
                              filteredBiddersData
                            );
                          }}
                        >
                          Excel
                        </span>
                      )}
                    </span>
                  </div>
                  <div className=" col-12 col-md-7 col-lg-7 d-flex gap-2 mb-2">
                    <SearchBoxWithCustomQuery
                      allData={allBiddersData}
                      setFilteredData={setFilteredBiddersData}
                      placeholder="Search..."
                      return_array={true}
                      keysToSearchOn={["bidder_name", "bidder_phone"]}
                    />
                  </div>
                </div>
              </div>
              <div
                className="container table-responsive"
                style={{
                  maxHeight: showAssetsTable ? "7rem" : "25rem",
                  transition: "max-height 0.5s ease-in-out",
                  // overflow: "hidden",
                }}
              >
                <TableWithProgressBar
                  data={filteredBiddersData}
                  columns={table_columns_nonparticipating_bidders_count.filter(
                    (column) => column
                  )}
                  loading={loading}
                  loading_cols={
                    table_columns_nonparticipating_bidders_count.filter(
                      (column) => column
                    ).length
                  }
                  loading_rows={10}
                  no_data_msg="No Data Found"
                  collapse="true"
                  flag="allBidders"
                />
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default AuctionAssetsData;
